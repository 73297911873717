@font-face {
  font-family: "Circular Spotify Text", Arial, sans-serif;
  src: url("../fonts/CircularSpotifyText-Medium.eot");
  src: url("../fonts/CircularSpotifyText-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/CircularSpotifyText-Medium.woff2") format("woff2"),
    url("../fonts/CircularSpotifyText-Medium.woff") format("woff"),
    url("../fonts/CircularSpotifyText-Medium.ttf") format("truetype"),
    url("../fonts/CircularSpotifyText-Medium.svg#CircularSpotifyText-Medium") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular Spotify Text", Arial, sans-serif;
  src: url("../fonts/CircularSpotifyText-Bold.eot");
  src: url("../fonts/CircularSpotifyText-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/CircularSpotifyText-Bold.woff2") format("woff2"),
    url("../fonts/CircularSpotifyText-Bold.woff") format("woff"),
    url("../fonts/CircularSpotifyText-Bold.ttf") format("truetype"),
    url("../fonts/CircularSpotifyText-Bold.svg#CircularSpotifyText-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular Spotify Text", Arial, sans-serif;
  src: url("../fonts/CircularSpotifyText-Black.eot");
  src: url("../fonts/CircularSpotifyText-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/CircularSpotifyText-Black.woff2") format("woff2"),
    url("../fonts/CircularSpotifyText-Black.woff") format("woff"),
    url("../fonts/CircularSpotifyText-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular Spotify Text Book", Arial, sans-serif;
  src: url("../fonts/CircularSpotifyText-Book.eot");
  src: url("../fonts/CircularSpotifyText-Book.eot?#iefix") format("embedded-opentype"),
    url("../fonts/CircularSpotifyText-Book.woff2") format("woff2"),
    url("../fonts/CircularSpotifyText-Book.woff") format("woff"),
    url("../fonts/CircularSpotifyText-Book.ttf") format("truetype"),
    url("../fonts/CircularSpotifyText-Book.svg#CircularSpotifyText-Book") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kalam", Arial, sans-serif;
  src: url("../fonts/Kalam-Regular.eot");
  src: url("../fonts/Kalam-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Kalam-Regular.woff2") format("woff2"),
    url("../fonts/Kalam-Regular.woff") format("woff"),
    url("../fonts/Kalam-Regular.ttf") format("truetype"),
    url("../fonts/Kalam-Regular.svg#Kalam-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter", Arial, sans-serif;
  src: url("../fonts/Inter-Regular.eot");
  src: url("../fonts/Inter-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter-Regular.woff2") format("woff2"),
    url("../fonts/Inter-Regular.woff") format("woff"),
    url("../fonts/Inter-Regular.ttf") format("truetype"),
    url("../fonts/Inter-Regular.svg#Inter-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --purple-dark: #4600a9;
  --white: #ffffff;
  --purple-grey-light: #f2efff;
  --dark-black: #1f1f25;
  --purple-shadow: rgb(70, 0, 169, 0.25);
  --Foundation-Purple-purple-50: #f2efff;
  --lg-min-width: 992px;
  --md-min-width: 768px;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  color: rgb(31 31 37 / 75%);
  font-size: 16px;
  line-height: 24px;
  /* overflow-x: hidden; */
  font-family: "Circular Spotify Text Book", Arial, sans-serif;
  text-align: start;
}

NavHashLink {
  text-decoration: none;
  /* Removes the underline */
  color: inherit;
  /* Inherits color from the parent element */
  cursor: pointer;
  /* Ensures the pointer cursor is shown on hover */
  background: none;
  /* Removes background */
  border: none;
  /* Removes border */
  padding: 0;
  /* Removes padding */
  margin: 0;
  /* Removes margin */
  all: unset;
  /* Unsets all inherited and default styles */
  display: inline;
  /* Ensures it behaves as a normal inline element */
  cursor: pointer;
  /* Ensures the pointer cursor is shown on hover */
  text-decoration: none;
  /* Ensures there is no underline */
  color: inherit;
  /* Inherits color from the parent element */
  background: none;
  /* Removes background */
  border: none;
  /* Removes border */
  padding: 0;
  /* Removes padding */
  margin: 0;
  /* Removes margin */
}

/* .page-wrapper.homePage {
  height: 100vh;
} */

.page-wrapper.homePage.loaded {
  height: auto;
}

.how-works-btn {
  padding: 16px 32px;
  border: 1px solid var(--purple-grey-light);
  border-radius: 51px;
  font-size: 16px;
  line-height: 24px;
  color: var(--purple-dark);
  padding-left: 55px;
  display: inline-block;
  background: var(--white) url(../img/play.svg) no-repeat 32px 18px;
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  /* Standard syntax */
}

#contact-form {
  width: 100%;
  max-width: 1800px;
  margin: 0px auto;
  display: flex;
  position: relative;
}

#contact-form:before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  height: calc(100vh - 64px);
  width: 50%;
  background: #f8f7ff;
}

#contact-form .form-left {
  /* width:50%; */
  padding: 50px 50px 50px 0px;
  background: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 480px;
  /* margin:0px auto; */
  /* padding-left: calc(50vw - 612px); */
}

#contact-form .form-left h4 {
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 16px;
}

#contact-form .form-left p {
  color: #1f1f25;
  padding-bottom: 0;
}

#contact-form .form-left .submit-btn {
  margin-top: 20px;
}

#contact-form.thankyou-page .form-left {
  display: block;
}

#contact-form .col-lg-6:last-child {
  align-self: center;
  padding-left: 0px;
  padding-right: 0px;
}

#contact-form .form-right {
  background: #f8f7ff;
  padding: 50px 25px 0px 0px;
  /* width:50%; */
  z-index: 2;
  position: relative;
  right: -12%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#contact-form .form-right p {
  color: #b78af7;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 348px;
  margin: 10px auto;
}

#contact-form.thankyou-page .form-right p {
  color: var(--purple-dark);
  opacity: 0.5;
}

#contact-form.thankyou-page .button.btn-white {
  border: 2px solid var(--purple-dark);
}

img {
  vertical-align: bottom;
  max-width: 100%;
  border-radius: 12px;
}

h1 {
  color: var(--purple-dark);
  font-family: "Circular Spotify Text", Arial, sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 900;
  line-height: 64px;
}

h2 {
  color: var(--purple-dark);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  font-family: "Circular Spotify Text", Arial, sans-serif;
}

h3 {
  font-size: 32px;
  color: var(--purple-dark);
  font-weight: 700;
  margin: 0;
  line-height: 40px;
  font-family: "Circular Spotify Text", Arial, sans-serif;
}

@media (max-width: 767px) {
  h3 {
    font-size: 22px;
    line-height: 28px;
  }
}

h4 {
  color: var(--purple-dark);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.12px;
  margin-bottom: 16px;
  font-family: "Circular Spotify Text", Arial, sans-serif;
}

/* @media (max-width: 767px) {
  h4 {
    font-size: 16px;
  }
} */

h5 {
  font-size: 22px;
  color: var(--purple-dark);
  font-weight: 700;
  line-height: 28px;
  font-family: "Circular Spotify Text", Arial, sans-serif;
}

@media (max-width: 767px) {
  h5 {
    font-size: 15px;
  }
}

h6 {
  font-size: 15px;
  color: #000;
  font-weight: normal;
  margin: 0;
  font-family: "Circular Spotify Text", Arial, sans-serif;
}

@media (max-width: 767px) {
  h6 {
    font-size: 14px;
  }
}

p {
  margin: 0 0 15px 0px;
  font-family: "Circular Spotify Text Book", Arial, sans-serif;
}

p a {
  color: #3538c3;
}

ul {
  margin: 0;
  padding: 0;
}

li:not(.text-content-page ol li, .text-content-page ul li) {
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.form-control {
  padding: 10px 14px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 12px;
  border: 1px solid #e4e4e4;
}

select.form-control:has(option:disabled:checked[hidden]) {
  color: rgb(31 31 37 / 40%);
}

.select-wrapper {
  position: relative;
}

.select-wrapper select.form-control {
  padding-right: 30px;
}

.select-wrapper:after {
  content: "";
  height: 7px;
  width: 10px;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 16px;
  pointer-events: none;
  transform: translateY(-50%);
  background: url(../img/arrow-down-grey.svg) no-repeat 0px 0px;
}

.phone-wrapper {
  position: relative;
}

.phone-wrapper .form-control {
  padding-left: 58px;
}

.phone-wrapper:before {
  content: "+91";
  position: absolute;
  height: 20px;
  width: 44px;
  display: flex;
  top: 13px;
  left: 7px;
  font-size: 16px;
  color: var(--dark-black);
  align-items: center;
  justify-content: center;
  border-right: 1px solid rgb(0 0 0 / 50%);
}

select.form-control option {
  color: rgb(31 31 37 / 75%);
}

.form-control::placeholder {
  color: rgb(31 31 37 / 40%);
}

.form-control::-ms-input-placeholder {
  /* Edge 12-18 */
  color: rgb(31 31 37 / 40%);
}

span.overflow-hidden {
  display: inline-block;
  vertical-align: bottom;
  border-bottom-left-radius: 24px;
  border-top-left-radius: 24px;
}

header {
  background: #27043f;
  /* box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05); */
  /* z-index: 3; */
  position: relative;
  min-height: 64px;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  /* padding: 12px 0px; */
  transition: all 0.5s;
}

.header-wrap {
  min-height: 64px;
  z-index: 9;
  position: relative;
}

.header-wrap.is-sticky {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 9999;
  animation: slideDown 0.5s ease-in-out;
  -webkit-animation: slideDown 0.5s ease-in-out;
  -o-animation: slideDown 0.5s ease-in-out;
  -ms-animation: slideDown 0.5s ease-in-out;
  opacity: 0.98;
  transition: all 0.5s;
}

.navbar-toggler {
  padding: 0px;
  border: 0;
}

.navbar-toggler svg {
  fill: var(--white);
  -webkit-fill: var(--white);
  -moz-fill: var(--white);
  -o-fill: var(--white);
  -ms-fill: var(--white);
}

header .request-demo {
  margin-left: 16px;
}

header.white .navbar-toggler svg {
  fill: #000000;
  -webkit-fill: #000000;
  -moz-fill: #000000;
  -o-fill: #000000;
  -ms-fill: #000000;
}

.navbar-toggler:focus {
  outline: none;
}

header .navbar {
  padding: 0px;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
}

header .navbar .navbar-collapse {
  justify-content: end;
  -webkit-justify-content: end;
  -moz-justify-content: end;
  -ms-justify-content: end;
  -o-justify-content: end;
}

.navbar a.logo,
.navbar a.logo:focus {
  padding: 0px 0px;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  list-style: none;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
}

.navbar li>a:hover,
.navbar li>a.active {
  position: relative;
  color: #e4e4e4;
}

.navbar li>a {
  color: #fefefe;
  transition: all 0.7s;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  -ms-transition: all 0.7s;
}

.navbar li:hover {
  position: relative;
}

.navbar li a,
.navbar li a:focus {
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  padding: 20px 16px !important;
  font-size: 16px;
  line-height: 24px;
  color: #e4e4e4;
  white-space: nowrap;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  text-decoration: none;
}

.navbar li.login-item {
  position: relative;
  padding-left: 32px;
}

.navbar li.login-item .divider-line {
  display: inline-block;
  height: 32px;
  width: 1px;
  position: absolute;
  top: 50%;
  background: #5045aa;
  opacity: 0.2;
  left: 16px;
  transform: translate(0px, -50%);
}

@media (min-width: 1241px) {
  .navbar .dropdown .dropdown-items {
    display: block;
    position: absolute;
    left: -105px;
    top: 100%;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    padding-top: 25px;
    width: 360px;
  }

  .navbar .dropdown .dropdown-items ul {
    margin: 0;
    display: block;
    position: relative;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(31, 31, 37, 0.2);
    -moz-box-shadow: 0px 4px 20px 0px rgba(31, 31, 37, 0.2);
    -webkit-box-shadow: 0px 4px 20px 0px rgba(31, 31, 37, 0.2);
    -ms-box-shadow: 0px 4px 20px 0px rgba(31, 31, 37, 0.2);
    -o-box-shadow: 0px 4px 20px 0px rgba(31, 31, 37, 0.2);
  }

  .navbar .dropdown .dropdown-items ul li ul.sub {
    box-shadow: none;
  }

  .navbar .dropdown .dropdown-items ul li ul.sub li {
    padding: 0px 0px !important;
    border: 0px;
    margin-bottom: 15px;
  }

  .navbar .dropdown .dropdown-items ul li ul.sub li a {
    padding: 0px 0px 0px 48px !important;
  }

  .navbar .dropdown:hover>.dropdown-items {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navbar .dropdown:hover>.dropdown-items>ul:before {
    content: "";
    border-bottom: solid 14px rgb(255 255 255);
    border-left: solid 12px transparent;
    border-right: solid 12px transparent;
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.navbar .dropdown ul .nav-link {
  padding: 16px 20px 16px 48px !important;
  font-size: 16px;
  text-transform: none;
  text-decoration: none;
  width: 100%;
  text-align: left;
}

.navbar .dropdown ul li .nav-link {
  position: relative;
  font-family: "Circular Spotify Text Book", Arial, sans-serif;
  font-weight: 400;
}

.navbar .dropdown ul li .nav-link span.arrow {
  height: 15px;
  width: 9px;
  position: absolute;
  top: 50%;
  right: 0px;
  display: inline-block;
  background: url("../img/menu-icons.svg") -9px -107px no-repeat;
  transform: translateY(-50%);
  visibility: hidden;
}

.navbar .dropdown ul li .nav-link:hover span.arrow {
  visibility: visible;
  right: 0px;
}

.navbar .dropdown ul li .nav-link:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 32px;
  width: 32px;
  display: inline-block;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;
}

.navbar .dropdown ul li.item1 a::after {
  background-image: url(../img/menu-icon-1.svg);
}

.navbar .dropdown ul li.item2 a::after {
  background-image: url(../img/menu-icon-2.svg);
}

.navbar .dropdown ul li.item3 a::after {
  background-image: url(../img/menu-icon-3.svg);
}

.navbar .dropdown ul li.item4 .nav-link::after {
  background-image: url(../img/menu-icon-4.png);
}

.navbar .dropdown ul li.item1:hover a::after {
  background-image: url(../img/menu-icon-1h.svg);
}

.navbar .dropdown ul li.item2:hover a::after {
  background-image: url(../img/menu-icon-2h.svg);
}

.navbar .dropdown ul li.item3:hover a::after {
  background-image: url(../img/menu-icon-3h.svg);
}

.navbar .dropdown ul li.item4:hover .nav-link::after {
  background-image: url(../img/menu-icon-4h.png);
}

.navbar .dropdown ul li {
  margin-left: 0px;
  padding: 0px 24px !important;
  border-top: 1px solid #ebf3ff;
}

.navbar .dropdown ul li:first-child {
  border-top: 0px;
}

.navbar .dropdown ul li .nav-link {
  color: #14131b;
  padding: 10px;
}

.navbar .dropdown ul li .nav-link:hover {
  color: #4600a9;
}

.button {
  font-family: "Circular Spotify Text", Arial, sans-serif;
  font-style: normal;
  text-align: center;
  padding: 16px 32px;
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  transition: all 0.7s;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  -ms-transition: all 0.7s;
  border-radius: 51px;
}

.btn-purple {
  background: var(--purple-dark);
  color: var(--white);
}

.button.medium {
  padding: 12px 24px;
}

.button.small {
  padding: 8px 16px;
}

.btn-purple:hover {
  box-shadow: 0px 4px 16px 0px rgba(70, 0, 169, 0.5);
  color: var(--white);
}

.btn-purple:active {
  background: #360083;
  box-shadow: 0px 4px 16px 0px rgba(70, 0, 169, 0.5);
  color: var(--white);
}

.btn-white {
  color: var(--purple-dark);
  background: #fff;
}

.btn-purple-light {
  background: var(--purple-grey-light);
  color: var(--purple-dark);
}

header .navbar-toggler:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
}

.invalid-feedback {
  display: block;
  font-size: 14px;
  margin-top: 0;
}

#testemonials {
  position: relative;
}

#testemonials .slide-main {
  overflow: hidden;
  padding: 50px 0px 75px;
}

#testemonials .slide-main .slider-img img {
  width: auto !important;
}

.slide-main .author {
  font-size: 14px;
  line-height: 20px;
  margin-top: 15px;
}

.slide-main .designation {
  color: var(--purple-dark);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.slide-main .content {
  font-size: 16px;
  line-height: 24px;
  opacity: 0.5;
  color: var(--dark-black);
  margin-top: 12px;
}

#testemonials .slide {
  border-radius: 24px;
  background: #fff;
  box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, 0.1);
  padding: 24px;
}

#testemonials .owl-carousel .owl-stage-outer {
  overflow: initial;
}

#testemonials .owl-theme .owl-dots {
  margin: 30px auto 0px;
}

#testemonials .owl-carousel .owl-nav button span {
  padding: 0px 24px;
  border-radius: 49px;
  background: #f5f5f5;
  font-size: 36px;
  line-height: 0px;
}

#testemonials .owl-carousel .owl-nav button:hover {
  background: inherit;
  color: inherit;
}

#testemonials .owl-carousel .owl-nav {
  color: var(--purple-dark);
  position: absolute;
  right: 0;
  bottom: -10px;
}

#testemonials .title img {
  margin-bottom: 20px;
}

#testemonials .title {
  margin-bottom: 40px;
}

#testemonials .owl-theme .owl-dots .owl-dot span {
  height: 12px;
  width: 12px;
  margin: 5px 12px;
  background: #e4e4e4;
}

#testemonials .owl-theme .owl-dots .owl-dot.active span {
  background: var(--purple-dark);
}

header.white {
  background: var(--white);
  /* box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05); */
}

header.white .navbar li>.nav-link {
  color: var(--dark-black);
  position: relative;
  border: 0;
  background: #fff;
}

header.white .navbar li.mobile-show {
  display: none;
}

header.white .navbar li.loan>a {
  color: var(--purple-dark);
}

header.white .navbar .navbar-collapse li>a:hover,
header.white .navbar li>a.active {
  position: relative;
  color: var(--purple-dark);
}

header.white .navbar .navbar-collapse>ul>li>a::before {
  background-color: var(--purple-dark);
}

header.white .btn-rounded.yellow {
  color: #fff;
  background: var(--purple-dark);
}

header.white .navbar .navbar-collapse>ul>li>a:before {
  content: "";
  background-color: var(--purple-dark);
}

.navbar .navbar-collapse>ul>li>a:before {
  background-color: #e4e4e4;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  bottom: 0;
  content: "";
  display: inline-block;
  height: 2.5px;
  left: 16px;
  position: absolute;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  width: 0;
}

.navbar .navbar-collapse>ul>li:hover>a:before,
.navbar .navbar-collapse>ul>li>a.active:before,
.navbar .navbar-collapse>ul>li>a:hover:before {
  visibility: visible;
  width: calc(100% - 32px);
}

#inner-banner {
  width: 100%;
  min-height: 319px;
  padding: 70px 0;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -o-align-items: flex-end;
  -ms-align-items: flex-end;
}

#inner-banner p {
  margin: 5px 0 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  font-family: "Circular Spotify Text Book", Arial, sans-serif;
}

#inner-banner .banner-text.product p {
  max-width: 360px;
}

#inner-banner .banner-text.features p {
  max-width: 330px;
}

#inner-banner h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -0.05em;
}

#inner-banner h6 {
  margin: 0 0 25px;
  color: #7e3bab;
  font-size: 16px;
  letter-spacing: -0.05em;
}

#inner-banner h6 a {
  color: #7e3bab;
}

#inner-banner h6 a:hover {
  color: #7e3bab;
  opacity: 0.9;
}

#faq-section {
  padding: 40px 0px 80px;
}

.faqpage {
  position: relative;
}

.faqpage section {
  position: relative;
  z-index: 1;
}

.faqpage::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 600px;
  background: linear-gradient(180deg, #ebeeff 45%, #fff 100%);
}

#faq-section .faq-head h1 {
  color: var(--purple-dark);
  text-align: center;
  font-family: "Circular Spotify Text", Arial, sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 900;
  line-height: 64px;
}

#faq-section .faq-head p {
  color: var(--purple-dark);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.75;
  margin-bottom: 20px;
}

#faq-section p {
  margin: 5px 0 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  font-family: "Circular Spotify Text Book", Arial, sans-serif;
  color: #6e6e6e;
}

#faq-section p a {
  color: #7e3bab;
  text-decoration: underline;
}

#faq-section .accordion {
  border: 0;
  margin: 0;
  padding: 40px 0 0;
}

#faq-section .accordion#features {
  padding-top: 0px;
  padding-bottom: 0px;
}

#faq-section .accordion-item {
  border: 0;
  margin-top: 12px;
  background-color: #fff;
  border: 2px solid #f2efff;
  border-radius: 16px;
}

#faq-section .accordion-item .accordion-header {
  padding: 12px 24px;
}

#faq-section .accordion-item .accordion-header button {
  font-size: 16px;
  color: var(--dark-black);
  line-height: 24px;
  font-family: "Circular Spotify Text", Arial, sans-serif;
  padding: 0px 35px 0px 0px;
  background: none;
}

#faq-section .accordion-item:last-child {
  margin-bottom: 0;
}

#faq-section .accordion-button:focus,
#faq-section .accordion-button:active {
  outline: none;
  box-shadow: none;
}

#faq-section .accordion-button:not(.collapsed) {
  background: none;
  color: #7e3bab;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
}

#faq-section .accordion-body {
  padding: 10px 24px 24px;
  color: var(--dark-black);
}

#faq-section .accordion-button::after {
  display: none;
}

#faq-section .accordion-button::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  right: 0;
  width: 12px;
  height: 8px;
  background: url(../img/faq-arrow-up.svg) no-repeat;
}

#faq-section .accordion-button.collapsed::before {
  background: url(../img/faq-arrow-down.svg) no-repeat;
}

/* FAQ Page Style */
.nav-tabs {
  border: 0px;
  column-gap: 12px;
  row-gap: 12px;
}

.nav-tabs li {
  display: block;
  margin: 0;
}

.nav-tabs .nav-link,
.nav-tabs .nav-link.active {
  border: 0px;
}

.nav-tabs .nav-link {
  border-radius: 16px;
  border: 2px solid #fff;
  background: #fff;
  padding: 10px 24px;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  color: #1f1f25;
  box-shadow: none;
}

.form-left .nav-tabs .nav-link {
  background: transparent;
  border-color: #e4e4e4;
}

#faq-section .nav-tabs .nav-link.active {
  box-shadow: none;
  border: 2px solid #e1ccff;
  background: #f2efff;
}

.thankyou-page .main-heading h3 {
  margin-top: 18px;
}

.thankyou-page .main-heading .sub-title {
  color: var(--dark-black);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 15px;
}

.thankyou-page .inner-title {
  color: var(--dark-black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.5;
  margin-top: 35px;
  margin-bottom: 12px;
}

.nav-tabs .nav-link h5 {
  color: var(--dark-black);
  font-family: "Circular Spotify Text", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-tabs .nav-link .knowmore {
  color: var(--purple-dark);
  display: none;
}

.nav-tabs .nav-link .knowmore::after {
  content: "";
  display: inline-block;
  width: 8px;
  height: 16px;
  background: url(../img/arrow-right.png) no-repeat;
  background-size: 100%;
  position: relative;
  margin-left: 8px;
  top: 4px;
}

.nav-tabs .nav-link p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: var(--dark-black);
  opacity: 0.7;
  line-height: 20px;
  margin-bottom: 0px;
}

.nav-tabs .nav-link.active h5,
.nav-tabs .nav-link:hover h5 {
  color: var(--purple-dark);
}

.nav-tabs .nav-link.active {
  border: 1px solid var(--purple-dark);
  box-shadow: 0px 20px 25px -20px rgba(54, 0, 131, 0.5);
}

#static-content {
  padding: 75px 0;
}

.inputLabel {
  font-size: 14px;
  display: block;
  line-height: 17px;
  font-family: "Circular Spotify Text Book", Arial, sans-serif;
  margin-top: 17px;
  margin-bottom: 5px;
}

.navbar ul {
  display: block;
}

.blogmain {
  padding: 10px 0;
}

.banner-post-slide {
  width: 90%;
  margin: 50px auto;
}

.banner-post-slide .blog-item {
  /* background-color: var(--white); */
  border-radius: 10px;
  overflow: hidden;
}

.banner-post-slide .blog-feature-data {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  padding: 0px 38px 50px;
  position: relative;
  gap: 20px;
}

.banner-post-slide .blog-feature-data .title a {
  font-size: 28px;
  line-height: 1.2;
  font-weight: 700;
  color: #333333;
}

.banner-post-slide .blog-feature-data .data {
  color: #4a4a4a;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 400;
  margin-top: 15px;
}

.banner-post-slide .meta-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 0px;
}

.banner-post-slide .meta-info .category a {
  color: #7e3bab;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.3;
  text-transform: uppercase;
}

.banner-post-slide .meta-info .date {
  color: #4a4a4a;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3;
}

.banner-post-slide .owl-theme .owl-nav,
.slider-main .owl-theme .owl-nav {
  text-align: left;
}

.banner-post-slide .owl-nav button,
.slider-main .owl-nav button {
  height: 40px;
  width: 40px;
  margin: 0px 10px;
  z-index: 1;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 50% !important;
  background: var(--purple-dark) !important;
  transition: all 0.3s;
  position: relative;
  opacity: 1 !important;
  border: 0;
}

.banner-post-slide .owl-nav button:hover,
.slider-main .owl-nav button:hover {
  opacity: 0.8 !important;
}

.banner-post-slide .owl-nav button span,
.slider-main .owl-nav button span {
  display: inline-block;
  font-size: 0px;
  background: url(../img/nav-hover.png) center no-repeat;
  width: 12px;
  height: 16px;
  background-size: contain;
}

.banner-post-slide .owl-nav button.owl-prev span,
.slider-main .owl-nav button.owl-prev span {
  transform: rotate(180deg);
}

section.blog-category {
  /* padding-top: 100px; */
}

section.blog-category:last-of-type {
  margin-bottom: 100px;
}

.blog-category .slide-head {
  color: #000;
  font-size: 23px;
  font-weight: 700;
  line-height: 1.3;
  margin-top: 65px;
  margin-bottom: 30px;
}

.blog-item.blog-card .blog-img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.blog-item.blog-card .blog-meta-info {
  padding-top: 15px;
}

.blog-item.blog-card .blog-meta-info .title {
  margin-bottom: 15px;
}

.blog-item.blog-card .blog-meta-info .title a {
  color: #333;
  font-size: 19px;
  font-weight: 700;
  line-height: 1.2;
  display: block;
}

.blog-item.blog-card .blog-meta-info .date {
  color: #74758c;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2;
}

.slider-main {
  position: relative;
}

.slider-main .cta-link {
  color: #333;
  font-size: 16px;
  font-family: "Inter", Arial, sans-serif;
  font-weight: 400;
  z-index: 1;
  text-decoration-line: underline;
  position: absolute;
  bottom: 5px;
}

.slider-main .cta-link {
  position: relative;
}

/* blog detail */
.blog-top-detail {
  text-align: center;
}

.blog-top-detail .subtitle {
  font-size: 16px;
  line-height: 24px;
  margin-top: 35px;
  color: #5e0a96;
  text-transform: uppercase;
}

.blog-top-detail .title {
  color: var(--dark-black);
  font-size: 45px;
  line-height: 52px;
  max-width: 715px;
  margin: 25px auto 20px;
}

.blog-banner {
  margin: 40px 0px;
}

.blog-top-detail .blog-socials {
  margin: 20px auto;
}

.blog-top-detail .title a {
  color: var(--dark-black);
}

.blog-top-detail .datetime {
  font-size: 16px;
  line-height: 24px;
}

.blog-socials ul li {
  display: inline-block;
  margin: 10px;
}

.blog-socials ul li a {
  box-shadow: 0px 2px 8px 0px #00000026;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block;
  background: url("../img//social-group.png") no-repeat 14px 11px;
}

.blog-socials ul li a.twitter {
  background-position: -48px 11px;
}

.blog-socials ul li a.linkedin {
  background-position: -112px 11px;
}

.blog-socials ul li a.gmail {
  background-position: -177px 11px;
}

.blog-banner img {
  width: 100%;
}

.blog-inner-content p {
  font-size: 18px;
  line-height: 2;
  font-weight: 500;
}

.blog-inner-content h3 {
  font-size: 36px;
  line-height: 44px;
  margin: 40px 0px 15px;
}

.blog-inner-content .add-container {
  width: 1040px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebebeb;
  font-size: 16px;
  line-height: 32px;
  height: 120px;
}

.blog-inner-content ol li {
  list-style-type: auto;
  font-size: 16px;
  line-height: 32px;
  font-family: "Circular Spotify Text Book", Arial, sans-serif;
  margin-top: 10px;
}

.recent-blogs {
  background: #f6f6f6;
  padding: 40px 0px 64px;
}

.recent-blogs h3 {
  position: relative;
  text-align: center;
  display: inline-block;
  color: var(--dark-black);
  font-size: 24px;
  line-height: 32px;
  margin-top: 8px;
  padding-bottom: 16px;
  margin-bottom: 24px;
}

.recent-blogs h3:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-bottom: 1px solid #cccccc;
  bottom: 0px;
}

.recent-blogs .list {
  margin-top: 24px;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
}

.recent-blogs .list h5 {
  margin-top: 16px;
}

.recent-blogs .list .date {
  margin-top: 16px;
  opacity: 0.5;
  color: var(--dark-black);
}

#home-main-section {
  padding: 120px 0 0px;
  background: linear-gradient(180deg, #ebeeff 50%, rgba(255, 255, 255, 1) 100%);
  position: relative;
  overflow: hidden;
}

#home-main-section .topcard {
  position: absolute;
  top: -60px;
  left: 0;
  display: block;
  max-width: 380px;
}

#home-main-section .container-md {
  position: relative;
}


#home-main-section .rightcard {
  max-width: 600px;
  position: absolute;
  top: 25%;
  right: 0%;
  display: block;
}

#home-main-section .container-md .row {
  position: relative;
  z-index: 1;
}

.herotext {
  position: relative;
}

.herotext h1 {
  font-size: 72px;
  line-height: 88px;
  color: #4600a9;
  text-transform: uppercase;
  font-weight: 900;
}

.herotext p {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #1f1f25;
  opacity: 0.8;
  padding: 0 10%;
}

.btn-yellow {
  border: 2px solid #e8a917;
  background: #fff;
  color: #e8a917;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  padding: 15px 40px 13px;
  box-shadow: 0 8px 20px rgba(232, 169, 23, 0.25);
}

.btn-yellow:hover {
  background: #e8a917;
  color: #fff;
}

.nofee {
  padding: 56px 0 0;
}

.nofee ul {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 32px;
  row-gap: 20px;
  flex-wrap: wrap;
}

.nofee ul li {
  font-size: 16px;
  line-height: 24px;
  color: #1f1f25;
  opacity: 0.5;
  margin: 0;
}

.certified-by {
  padding-top: 50px;
  padding-bottom: 10px;
}

.poweredBy {
  padding: 16px 0 0;
}

/* .poweredBy {
  padding: 56px 0 0;
} */

.poweredBy p {
  font-size: 12px;
  line-height: 20px;
}

.poweredBy img {
  max-height: 48px;
  display: block;
  margin: 0 auto;
}

.poweredBy p {
  font-size: 16px;
  line-height: 24px;
  color: #1f1f25;
  opacity: 0.5;
  margin: 0 0 16px;
}

.imageContainer {
  display: flex;
  justify-content: left;
  align-items: center;
}

.imageContainer img {
  /* margin-right: 12px; */
  /* box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); */
}

/* Media Query for mobile */
@media (max-width: 768px) {
  .imageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .imageContainer img {
    margin-bottom: 12px;
    margin-right: auto;
    margin-left: auto;
  }
}

#home-main-section .row.main-p {
  margin-top: -18px;
}

#home-main-section img.main-img {
  position: relative;
  top: -64px;
  left: -6.5%;
  max-width: 920px;
}

#home-main-section .how-works-btn {
  margin-left: 24px;
}

#home-main-section .main-p p {
  max-width: 475px;
}

#home-main-section .btns-group {
  margin-top: 40px;
}

#home-main-section .cards {
  margin-top: 124px;
}

#cards {
  margin-top: -70px;
}

.cards .card {
  border-radius: 24px;
  border: 1px solid #e1ccff;
  min-height: 200px;
}

.cards p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--purple-dark);
  margin-top: 12px;
}

.cards .card.card-1 {
  overflow: hidden;
}

.cards .card.card-2 {
  /* background: var(--white) url(../img/card-2-bg.svg) no-repeat left bottom;*/
  padding: 28px 24px;
  /* overflow:hidden; */
}

.cards .card.card-2 img {
  max-width: 120px;
}

.cards .card p {
  color: var(--dark-black);
  font-size: 14px;
  line-height: 20px;
  opacity: 0.75;
}

.cards .card.card-2 p {
  margin-top: 25px;
  margin-bottom: 22px;
}

.cards .card.card-3 {
  /* background: var(--white) url(../img/card-3-bottom-bg.svg) no-repeat right bottom; */
  position: relative;
  overflow: hidden;
  padding: 24px;
}

.hover-box img.hover-img {
  transition: all 0.8s ease-in-out;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
}

.hover-box:hover img.hover-img {
  transform: scale(1.05);
}

.cards .card.card-3 p {
  margin-bottom: 0px;
  margin-top: 4px;
}

.cards .card.card-3:before {
  height: 110px;
  width: 110px;
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  /* background: url(../img/card-3-top-bg.svg) no-repeat 0px 0px; */
}

.cards .card.card-3 img.coupon-img {
  width: 62%;
  position: relative;
  left: -24px;
  bottom: -24px;
}

.card-3 .giftbox {
  position: absolute;
  right: 24px;
  bottom: 24px;
  max-width: 64px;
}

.cheque {
  max-width: 360px;
  padding-top: 12px;
}

.homegetpaid {
  padding: 50px 0px 60px;
  background: #ffffff;
}

.get-paid .directions {
  border-radius: 50%;
  height: 64px;
  width: 64px;
  display: inline-block;
  background: var(--purple-grey-light);
  text-align: center;
  line-height: 52px;
  margin-bottom: 22px;
}

.get-paid .gradient-card {
  border-radius: 24px;
  background: linear-gradient(265deg, var(--purple-dark) 0%, #6f24d9 100%);
  min-height: 360px;
  margin-top: 30px;
  position: relative;
}

.gradient-card,
.purple-gradient-box,
.money-transfer .gradient-box {
  transition: all 0.8s ease-in-out;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
}

.gradient-card:hover,
.purple-gradient-box:hover,
.money-transfer .gradient-box:hover {
  box-shadow: 0px 65px 60px -40px rgba(81, 10, 182, 0.4);
}

.get-paid .gradient-card .inner-content {
  position: relative;
  z-index: 1;
}

.get-paid .gradient-card:after,
.money-transfer .gradient-box:after {
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 24px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: url(../img/dark-gradient-curve.svg) repeat 0px 0px;
  /* background-size:cover; */
  opacity: 0.6;
}

/* .get-paid .gradient-card .overview-frame {
  z-index: 1;
  position: absolute;
  right: 0px;
  top: -54px;
  max-width: 650px;
} */

.get-paid .gradient-card .overview-frame {
  z-index: 1;
  position: absolute;
  right: 0px;
  top: 1px;
  max-width: 500px;
  border-bottom-right-radius: 22px;
}

.get-paid .gradient-card .columns-box {
  padding: 56px 64px 56px;
}

.get-paid .gradient-card .columns-box h3 {
  color: #fff;
  margin: 0 0 24px;
}

.get-paid .gradient-card h4 {
  color: var(--purple-grey-light);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.12px;
}

.btn-white.op25 {
  background: rgba(255, 255, 255, 0.25);
  color: #fff;
}

.get-paid .gradient-card p {
  color: #fff;
  font-weight: 400;
  opacity: 0.75;
}

.get-paid .gradient-card .action {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 16px;
  margin: 33px 0 0;
}

.head-sub {
  color: var(--purple-dark);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.6;
}

.bulk-payment h4 {
  margin-top: 15px;
}

.bulk-payment .button {
  margin-top: 35px;
}

.payment-reminder-steps {
  padding: 100px 0px 35px;
}

.payment-reminder-steps .outer-box {
  position: relative;
}

.grey-pattern-full {
  border-radius: 24px;
  background: var(--purple-grey-light) url(../img/pattern-grey-bg.svg) repeat 0px 0px;
  padding: 30px 64px;
}

.payment-reminder-steps .outer-box ul {
  display: flex;
  justify-content: space-between;
  margin: 0px;
  padding: 0px;
  width: 90%;
}

.payment-reminder-steps .outer-box ul li .hover-box {
  height: 144px;
  width: 144px;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.75);
  padding: 10px;
  color: #000000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-reminder-steps .outer-box .inner {
  padding-top: 30px;
  background: url(../img/curve-indicators.png) no-repeat 78% 14%;
  background-size: 92%;
}

.payment-reminder-steps .bottom-content {
  margin-top: 40px;
}

.payment-reminder-steps .bottom-content .btn-white {
  position: absolute;
  right: 33px;
}

.know-about-pos {
  padding: 35px 0px 75px;
}

.know-about-pos .card {
  border-radius: 24px;
  border: 1px solid #e1ccff;
  padding: 21px 30px;
  min-height: 186px;
}

.know-about-pos .card h2 {
  font-size: 45px;
  line-height: 52px;
  margin-bottom: 0px;
}

.know-about-pos .card h2 span.currency {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.know-about-pos .card h2 span.sub {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.12px;
}

.know-about-pos .card h4 {
  margin-bottom: 10px;
}

.know-about-pos .card p {
  margin-bottom: 0px;
}

/*
.know-about-pos .card.card-1 {
  background: var(--white) url(../img/know-pos-card-1-bg.svg) no-repeat bottom left;
}

.know-about-pos .card.card-2 {
  background: var(--white) url(../img/know-pos-card-2-bg.svg) no-repeat top right;
}

.know-about-pos .card.card-3 {
  background: var(--white) url(../img/know-pos-card-3-bg.svg) no-repeat bottom right;
}
*/

.about-pos-link {
  color: var(--purple-dark);
  font-size: 16px;
  font-family: "Circular Spotify Text", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding-right: 24px;
  margin-top: 12px;
  display: inline-block;
  margin-left: 7px;
  position: relative;
}

.about-pos-link:after {
  content: "";
  width: 16px;
  height: 17px;
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0px;
  background: url(../img/arrow-up.svg) no-repeat 0px 0px;
}

.business-cc {
  padding: 60px 0px;
  background: #ffffff;
}

.business-cc .main-head {
  margin-bottom: 48px;
}

.business-cc .main-head .head-icon {
  background-color: #fff;
}

.purple-gradient-box {
  border-radius: 24px;
  min-height: 300px;
  padding: 50px 60px;
  background: linear-gradient(265deg, #6f24d9 0%, var(--purple-dark) 100%);
  position: relative;
}

.purple-gradient-box:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 24px;
  background: url(../img/dark-gradient-curve.svg) repeat 0px 0px;
  opacity: 0.6;
}

.purple-gradient-box .inner-box {
  position: relative;
  z-index: 2;
}

.business-cc .purple-gradient-box img.credit {
  position: absolute;
  top: -30px;
  right: -160px;
  max-width: 600px;
}

.business-cc .purple-gradient-box h2 {
  color: #fff;
  margin: 0 0 16px;
}

.business-cc .button {
  margin: 24px 0 0;
}

.business-cc .purple-gradient-box .content-box h4 {
  color: var(--purple-grey-light);
}

.business-cc .purple-gradient-box .content-box p {
  color: var(--white);
  opacity: 0.75;
  margin: 16px 0 0;
}

.business-cc .purple-gradient-box .content-box h3 {
  color: var(--white);
}

.main-head .head-icon {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background-color: var(--purple-grey-light);
  display: inline-block;
  margin-bottom: 10px;
}

.main-head .head-icon.cc-icon {
  background-image: url(../img/cc-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.virtual-cards {
  padding-bottom: 70px;
  background: #ffffff;
}

.virtual-cards .card-group {
  padding: 145px 0px;
  background: url(../img/cards-group.png) no-repeat top right;
  min-height: 420px;
  background-size: 65%;
}

.virtual-cards .card-group .btn-purple-light {
  margin-top: 12px;
}

.virtual-cards p {
  margin: 16px 0 0;
}

#available-on-mobile {
  padding: 90px 0px 120px;
}

.available-mobile {
  border-radius: 24px;
  border: 1px solid #e1ccff;
  background: #f8f7ff;
  min-height: 280px;
  overflow: hidden;
  padding: 48px 64px;
}

.available-mobile .col-md-6 {
  position: relative;
}

.available-mobile .col-md-6.frame img {
  position: absolute;
  top: -48px;
  right: 0;
  max-width: 506px;
}

.available-mobile h3 {
  margin-bottom: 16px;
}

.available-mobile .col-md-6 p {
  color: #1f1f25;
  opacity: 0.6;
  padding-right: 10%;
}

.available-mobile .device-list {
  margin-top: 32px;
}

.available-mobile .device-list ul {
  margin: 0px;
  padding: 0px;
}

.available-mobile .device-list ul li {
  display: inline-block;
  margin-right: 32px;
}

.gradient-text-card {
  padding: 60px 0px 130px;
}

.gradient-text-card .value {
  background: linear-gradient(157deg,
      #dab029 29%,
      rgba(218, 176, 41, 0.25) 51.5%,
      #dab029 64.06%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: 120px;
}

.gradient-text-card .value span {
  font-size: 56px;
}

.gradient-text-card p {
  max-width: 315px;
  margin-left: auto;
  margin-right: auto;
}

.trusted-partners h2 {
  color: #fff;
}

.trusted-partners p {
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  opacity: 0.75;
  margin: 20px 0 0;
}

.partners {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 0 0;
}

.comingsoontext {
  margin: 24px 0 0;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-style: italic;
  font-weight: 400;
  opacity: 0.5;
  width: 100%;
  display: block;
}

.trusted-partners {
  border-radius: 0px;
  padding: 56px 0px;
  min-height: initial;
  margin: 0 0 50px 0px;
}

.trusted-partners img {
  max-height: 48px;
  display: block;
  margin: 10px 12px;
}

.trusted-partners .inner-box h4 {
  color: var(--white);
}

.trusted-partners .inner-box .right-content .partners {
  margin-top: 50px;
  margin-bottom: 15px;
}

.trusted-partners .inner-box .right-content p {
  color: var(--white);
  opacity: 0.75;
}

.trusted-partners:hover {
  box-shadow: none !important;
}

.monitor-spending {
  padding: 50px 0px 60px;
  overflow: hidden;
  background: #ffffff;
}

.monitor-spending .vendor-payments-text {
  padding: 48px 26px 0 56px;
}

.vendor-payments-text p {
  margin: 16px 0 0;
}

.monitor-spending .grey-pattern-full {
  padding: 0px 0px;
  position: relative;
  border: 1px solid #b78af7;
  overflow: hidden;
}

.monitor-spending .col-8 img {
  border-radius: 0 0 25px 0;
}

.monitor-spending .grey-pattern-full:after,
.bulk-payment .grey-pattern-full:after {
  content: "";
  height: 95px;
  border-radius: 24px;
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: 0px;
  box-shadow: 0px -100px 75px -100px rgba(70, 0, 169, 0.4) inset;
}

.bulk-payment .grey-pattern-full {
  position: relative;
  overflow: hidden;
}

.bulk-payment .grey-pattern-full:after {
  height: 86px;
}

.bulk-payment .grey-pattern-full img {
  width: 100%;
}

.cardcorner {
  border-bottom-left-radius: 24px;
  max-width: 285px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0;
}

.vendorimg img {
  display: block;
  /* object-fit: contain; */
}

.monitor-spending .expansion-trip {
  position: absolute;
  top: 26%;
  right: -8%;
  max-width: 360px;
}

.cc-actions {
  max-width: 424px;
}

.grey-bg-2 {
  background: linear-gradient(180deg, #ebeeff 47.71%, #fff 100%);
}

.about-top-banner {
  padding: 80px 0px;
  min-height: 480px;
  position: relative;
}

.about-top-banner .container-md {
  position: relative;
  z-index: 1;
}

.about-top-banner .row {
  position: relative;
}

.about-top-banner .about-img {
  position: absolute;
  top: 55px;
  left: -4%;
  max-width: 600px;
}

.about-top-banner h2 {
  padding-bottom: 24px;
}

.about-top-banner .inner-row p {
  margin: 24px 0 0;
  color: #1f1f25;
  font-weight: 400;
}

.about-content .partners {
  margin-top: 22px;
  padding: 0;
  justify-content: start;
  justify-content: flex-start;
}

.about-content .partners img {
  max-height: 48px;
}

.about-content .partners .comming-soon {
  color: rgb(70 0 169 / 50%);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: inline-block;
  vertical-align: super;
  margin-left: 20px;
}

.about-content label {
  color: var(--dark-black);
  font-style: normal;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 12px;
}

.about-content p {
  font-style: normal;
  font-weight: 400;
  color: rgba(31, 31, 37, 0.75);
}

.about-content a {
  color: #1c74da;
  font-family: "Circular Spotify Text", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.about-content .about-content-inner {
  /* background-image: url(../img//about-bg.png); */
  background-repeat: no-repeat;
  background-position: 192px 0px;
  background-size: 80%;
  /* padding-bottom: 80px; */
}

.about-content .about-content-inner .row {
  padding-bottom: 80px;
}

.cta-bg-curve {
  min-height: 288px;
  background: url(../img/heading-bg-curve.svg) no-repeat top center;
  padding-top: 68px;
}

.cta-bg-curve.inner {
  min-height: 320px;
}

.cta-bg-curve h4 {
  margin-bottom: 22px;
}

/** current account **/
.inner-top-section {
  position: relative;
}

.inner-top-section .inner-content {
  padding: 48px 0px 60px;
}

.inner-top-section .right-card {
  position: absolute;
  right: 0px;
  top: 60%;
  transform: translateY(-60%);
}

.inner-top-section .inner-content .page-title {
  color: #4600a9;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 12px;
  opacity: 0.5;
}

.inner-top-section .inner-content .action-btns {
  margin-top: 32px;
}

.inner-content .action-btns .how-works-btn {
  margin-left: 16px;
}

.inner-top-section .overview-img {
  margin-top: 80px;
}

.inner-top-section h2 {
  font-weight: 700;
  letter-spacing: -0.5px;
}

.current-ac-figures {
  padding: 80px 0px 120px;
}

.current-ac-figures .content-box {
  padding: 0px 70px;
}

.current-ac-figures h5 {
  color: var(--dark-black);
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
}

.current-ac-figures p {
  font-weight: 400;
  margin: 16px 0 0;
}

.switch-account {
  padding-bottom: 80px;
}

.switch-account .main-box {
  padding: 48px 56px;
  border-radius: 24px;
  border-radius: 24px;
  border: 1px solid #e1ccff;
  background: linear-gradient(268deg, rgba(242, 235, 255, 0) 0%, #f2ebff 100%);
}

.switch-account .main-box .rectangle-box {
  border-radius: 16px;
  border: 1px solid #e1ccff;
  background: var(--white);
  min-height: 208px;
}

.switch-account .main-box .inner-content {
  padding: 0;
}

.switch-account .main-box .inner-content p {
  margin: 16px 0 0;
}

.switch-account .main-box .inner-content .text-end p {
  margin: 0;
}

.switch-account .main-box .inner-content .text-end .button {
  margin: 36px 0 0;
}

.switch-account .main-box .btn-purple {
  margin-left: 32px;
}

.money-transfer {
  padding-bottom: 80px;
}

.money-transfer.gradient-box {
  background: linear-gradient(265deg, var(--purple-dark) 0%, #7229db 100%);
  position: relative;
  padding: 48px 0;
}

.money-transfer.gradient-box:after {
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 24px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: url(http://localhost:3000/static/media/dark-gradient-curve.9b8dab2fda6de742ee6e.svg) repeat 0px 0px;
  opacity: 0.6;
}

.money-transfer .content-box {
  z-index: 2;
  position: relative;
}

.money-transfer .money-transfer-content {
  padding: 112px 60px 0 0;
}

.money-transfer .money-transfer-content h3 {
  position: relative;
  color: #fff;
  padding-right: 10%;
}

.money-transfer .money-transfer-content p {
  margin: 16px 0 0;
  color: rgba(255, 255, 255, 0.75);
}

.money-transfer .gradient-box .col-md-6 {
  position: relative;
}

.money-transfer .gradient-box .col-md-6 .mapImg {
  position: relative;
}

.money-transfer .gradient-box .col-md-6 .mapImg img {
  display: block;
  max-width: 520px;
}

.money-transfer .gradient-box h5 {
  color: var(--white);
  max-width: 238px;
  margin-bottom: 16px;
}

.money-transfer .gradient-box p {
  color: var(--white);
  opacity: 0.75;
  margin-bottom: 32px;
}

.payment-schedule-new {
  position: relative;
  background: #ece8ff;
  padding: 120px 0;
}

.payment-schedule-new .payment-text {
  padding: 0 0 0 40px;
}

.payment-schedule-new .payment-text p {
  margin: 24px 0 0;
  color: rgb(31, 31, 37, 0.75);
  max-width: 420px;
}

.payment-schedule-new .row {
  margin-bottom: 120px;
  position: relative;
  z-index: 11;
}

.payment-schedule-new .row:last-child {
  margin-bottom: 0;
}

.payment-schedule-new .row:nth-child(2n) {
  flex-direction: row-reverse;
}

.payment-schedule .paySchedule-card {
  border-radius: 24px;
  /* background: radial-gradient(869.42% 141.42% at 0% 0%, #A67CFF 0%, #6225B1 100%); */
  background: radial-gradient(124% 239.42% at -6% 33%,
      #a67cff 0%,
      #6225b1 85.19%);
  box-shadow: 0px -359px 25px -350px rgba(94, 10, 150, 0.2) inset;
  padding-left: 32px;
  position: relative;
  overflow: hidden;
}

.payment-schedule .paySchedule-card:before,
.payment-schedule .transaction-card:before {
  content: "";
  height: 42px;
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: 0px;
  box-shadow: 0px -359px 25px -350px rgba(94, 10, 150, 0.2) inset;
  border-radius: 24px;
  z-index: 3;
}

.payment-schedule .paySchedule-card .inner-content {
  z-index: 1;
  position: relative;
}

.payment-schedule .paySchedule-card .inner-content h1 {
  color: #fff;
  font-family: "Circular Spotify Text", Arial, sans-serif;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: 96px;
  opacity: 0.4;
  padding: 13px 48px 0px 30px;
  position: relative;
  top: 40px;
}

@media screen and (max-width: 992px) {
  .payment-schedule-new .payment-image {
    width: 100%;
    margin-top: -20px;
  }

  .payment-schedule-new .payment-image .image-wrap img {
    width: 100%;
    margin-top: 0px;
  }

  .payment-schedule-new .virtual-text {
    padding-top: 1rem !important;
    padding-bottom: 3rem !important;
  }
}

.payment-schedule .paySchedule-card:after,
.payment-schedule .transaction-card:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0.5;
  border-radius: 24px;
  background: url(../img/pattern-purple-medium.svg) repeat 0px 0px;
}

.payment-schedule .transaction-card {
  border-radius: 24px;
  background: linear-gradient(160deg, #a076f8 13.5%, #5225b1 86.5%);
  box-shadow: 0px -359px 25px -350px rgba(94, 10, 150, 0.2) inset;
  padding-top: 40px;
  overflow: hidden;
  position: relative;
}

.payment-schedule .transaction-card img {
  position: relative;
  z-index: 2;
}

.card-inner-block {
  margin-top: 15px;
}

.card-inner-block h6 {
  color: var(--purple-dark);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  max-width: 151px;
}

.card-inner-block p {
  color: var(--dark-black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.6;
}

.approve-payment {
  padding: 80px 0px;
}

.approve-payment .payment-approval {
  border-radius: 20px;
  border: 1px solid #ced5fa;
  background: linear-gradient(180deg, #fff 0%, #e5e1ff 100%);
  box-shadow: 0px -359px 25px -350px rgba(94, 10, 150, 0.2) inset;
}

.approve-payment .payment-approval .img-box {
  overflow: hidden;
  max-height: 208px;
  height: 208px;
}

.approve-payment .payment-approval .img-box img {
  position: relative;
  top: 31px;
  right: -39px;
}

.approve-payment .manage-team-access {
  border-radius: 20px;
  border: 1px solid #ced5fa;
  background: linear-gradient(180deg, #cbbfff 0%, #fbf9ff 100%);
  padding: 40px;
  min-height: 208px;
  height: 208px;
}

.approve-payment .card-inner-block p {
  opacity: 0.75;
}

.current-account .overview-img img {
  max-width: 785px;
}

.current-account.inner-top-section .overview-img {
  margin-top: 12px;
}

.current-account .right-card {
  max-width: 211px;
}

.collection-reminder {
  padding: 100px 0px;
}

.collection-reminder .content-box {
  z-index: 2;
  position: relative;
}

.collection-reminder .purple-gradient-box {
  padding: 0px 0px;
  min-height: 360px;
  overflow: hidden;
}

.collection-reminder .purple-gradient-box:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 80px;
  width: 100%;
  border-radius: 24px;
  z-index: 3;
  box-shadow: 0px -100px 75px -100px rgba(70, 0, 169, 0.4) inset;
}

.collection-reminder .main-head .head-icon.reminder-icon {
  background-image: url("../img/reminder.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.collection-reminder .main-head h2 {
  margin-bottom: 3px;
}

.collection-reminder .main-head {
  margin-bottom: 48px;
}

.collection-reminder .content-box .col-md-6 {
  position: relative;
}

.collection-reminder .content-box img {
  position: absolute;
  right: 5px;
  bottom: -29px;
}

.collection-reminder .content-box h4 {
  color: var(--white);
  max-width: 265px;
}

.collection-reminder .content-box .left-content {
  padding: 48px 64px;
}

.collection-reminder .content-box p {
  color: var(--white);
  opacity: 0.75;
}

.collection-reminder .content-box .btn-purple {
  background: rgba(255, 255, 255, 0.25);
  margin-left: 16px;
}

.collection-reminder .content-box .btn-groups {
  margin-top: 35px;
}

.reminder-payments {
  padding: 48px 0px 0px;
}

.reminder-payments .reminder {
  border-radius: 24px;
  background: #e6e1ff url("../img/pattern-grey-bg.svg");
  text-align: center;
  position: relative;
  overflow: hidden;
  padding-top: 16px;
}

.reminder-payments .reminder:after,
.reminder-payments .payment:after {
  content: "";
  height: 70px;
  border-radius: 24px;
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 2;
  box-shadow: 0px -359px 25px -350px rgba(94, 10, 150, 0.2) inset;
}

.reminder-payments .payment {
  background: #efecfc;
  border-radius: 24px;
  padding-top: 18px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.reminder-payments .payment:before {
  content: "";
  position: absolute;
  opacity: 0.25;
  border-radius: 24px;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background: url("../img/grey-light-pattern.svg") repeat 0px 0px;
}

.reminder-payments .payment img {
  z-index: 1;
  position: relative;
}

.receive-payment {
  padding: 100px 0px 50px;
}

.receive-payment .card-payment {
  border-radius: 24px;
  background: var(--purple-grey-light);
  position: relative;
  min-height: 360px;
}

.receive-payment .card-payment:after {
  border-radius: 24px;
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: url(../img/purple-gradient-light-curve.svg) repeat 0px 0px;
  opacity: 0.05;
}

.receive-payment .card-payment .row {
  position: relative;
  z-index: 2;
}

.receive-payment .card-payment .row .col-md-4 {
  position: relative;
}

.receive-payment .main-head {
  margin-bottom: 48px;
}

.receive-payment .card-payment .row .col-md-4 img {
  position: absolute;
  top: -242px;
  left: -22px;
}

.card-payment .inner-content {
  padding: 60px 75px 40px 0px;
}

.card-payment .inner-content .text-content {
  max-width: 513px;
}

.card-payment .inner-content .text-content h4 {
  max-width: 226px;
}

.card-payment .inner-content .btn-box {
  margin-top: 35px;
}

.main-head .head-icon.store-icon {
  background-image: url(../img/store-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.track-receive-payment {
  padding: 70px 0px;
}

.track-receive-payment .content-box {
  padding: 0px 40px;
}

.track-receive-payment .group-img {
  margin-top: 24px;
}

.track-receive-payment .group-img .middle-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.track-receive-payment .group-img .middle-img img {
  margin-top: 35px;
}

.available-versions {
  padding-bottom: 120px;
}

.inner-top-section.payment-collection-banner .overview-img {
  margin-top: 0px;
}

.payment-collection-banner img {
  max-width: 1024px;
}

@media screen and (max-width: 992px) {
  .ccc-main-page .ccc-main-img img {
    padding-right: 0px;
    margin-left: -15px;
  }

  .ccc-main-page .ccc-main-img img {
    max-width: 115%;
  }
}

.get-paid-collection img {
  max-width: 580px;
}

.about-reminder {
  padding: 120px 0px 80px;
}

.about-reminder .about-reminder-inner {
  padding: 0px;
}

.about-reminder .about-reminder-inner h4 {
  max-width: 300px;
}

.about-reminder .about-reminder-inner *:last-child {
  margin-bottom: 0;
}

.payment-schedule-new.collection-page {
  background: linear-gradient(280deg, #4600a9 0%, #7229db 100%);
  padding-bottom: 240px;
}

.payment-schedule-new.collection-page.nobg {
  padding: 0;
  background: transparent;
  margin: 80px 0;
}

.payment-schedule-new.collection-page p {
  color: rgba(255, 255, 255, 0.75);
}

.payment-schedule-new.collection-page.nobg h3 {
  color: var(--purple-dark);
}

.payment-schedule-new.collection-page:not(.nobg) .payment-text h3 {
  color: #fff;
}

.hover-out img {
  transition: all 0.8s ease-in-out;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
}

.hover-out:hover img.hover-img {
  transform: scale(1.05);
}

.screminder {
  padding-top: 0;
  margin-top: -150px;
  position: relative;
  z-index: 1;
}

.screminder .main-box {
  border: 2px solid #e1ccff;
}

.screminder .main-box .col-md-5 {
  padding-top: 24px;
}

.get-paid .main-box {
  border-radius: 24px;
  background: var(--purple-grey-light);
  position: relative;
  overflow: hidden;
  padding-top: 48px;
}

.get-paid .main-box:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: url(../img/purple-gradient-light-curve.svg) repeat 0px 0px;
  opacity: 0.06;
  border-radius: 24px;
}

.get-paid .main-box .inner-content {
  position: relative;
  z-index: 2;
}

.get-paid .main-box h5 {
  margin-top: 10px;
}

.get-paid .main-box .button {
  margin-top: 25px;
}

.get-paid .main-box p {
  color: rgb(31 31 37 / 75%);
  margin: 12px 0 0;
}

.send-customize-reminder {
  padding: 40px 0px;
}

.send-customize-reminder .send-reminder {
  border-radius: 24px;
  background: radial-gradient(869.42% 141.42% at 0% 0%,
      #b27cff 0%,
      #5f25b1 100%);
  padding: 0px 38px;
  position: relative;
  overflow: hidden;
}

.send-customize-reminder .send-reminder .col-md-6 {
  padding-left: 0px;
  padding-right: 0px;
}

.send-customize-reminder .send-reminder:before,
.send-customize-reminder .customize-reminder:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  border-radius: 24px;
  opacity: 0.7;
  background: url(../img/pattern-purple-medium.svg) repeat 0px 0px;
}

.send-customize-reminder .send-reminder:after,
.send-customize-reminder .customize-reminder:after {
  content: "";
  height: 70px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  box-shadow: 0px -359px 25px -350px rgba(94, 10, 150, 0.2) inset;
  z-index: 2;
}

.send-customize-reminder .row {
  position: relative;
  z-index: 2;
  height: 100%;
}

.send-customize-reminder.new .send-reminder {
  padding-left: 80px;
  padding-right: 60px;
}

.send-customize-reminder.new .send-reminder .business-invoice {
  padding-top: 0;
  padding-right: 80px;
}

.send-customize-reminder .send-reminder h3 {
  color: var(--white);
}

.send-customize-reminder .send-reminder p {
  color: var(--white);
  opacity: 0.75;
  margin: 10px 0 0;
}

.send-customize-reminder .customize-reminder {
  border-radius: 24px;
  background: linear-gradient(160deg, #a769ff 13.5%, #5f25b1 86.5%);
  position: relative;
  overflow: hidden;
  padding-top: 44px;
  height: 400px;
  padding-top: 76px;
}

.send-customize-reminder .customize-reminder .col-md-6 {
  position: relative;
}

.send-customize-reminder .customize-reminder .col-md-6 img {
  position: relative;
  left: 30px;
}

.send-customize-reminder .customize-reminder h4 {
  color: var(--white);
  letter-spacing: 0.12px;
}

.send-customize-reminder .customize-reminder p {
  color: var(--white);
  opacity: 0.6;
  font-size: 14px;
  line-height: 20px;
}

.send-customize-reminder .customize-reminder .content-left {
  max-width: 249px;
  margin-top: 40px;
}

.send-customize-reminder.new {
  margin-top: -150px;
  padding-top: 0;
}

.scremindernew {
  border-radius: 24px;
  background: var(--purple-grey-light);
  position: relative;
  overflow: hidden;
  padding-top: 48px;
  border: 2px solid #e1ccff;
  position: relative;
  width: 100%;
}

.scremindernew::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: url(../img/purple-gradient-light-curve.svg) repeat 0px 0px;
  opacity: 0.06;
  border-radius: 24px;
}

.scremindernew .get-paid-collection img {
  margin-left: 20px;
}

.col-md-6.pl-0 {
  padding-left: 0px;
}

.schedule-payment {
  padding: 40px 0px 40px;
}

.schedule-payment .reminder-payments {
  padding: 0px 0px;
}

.schedule-payment .reminder-payments .reminder {
  border-radius: 24px;
  background: linear-gradient(180deg, #d0c6ff 0%, #8d7bdd 100%);
  box-shadow: 0px -359px 25px -350px rgba(94, 10, 150, 0.2) inset;
  position: relative;
}

.schedule-payment .reminder-payments .payment {
  border-radius: 24px;
  background: radial-gradient(124% 239.42% at -6% 33%,
      #d7baff 0%,
      #5f25b1 85.19%);
  box-shadow: 0px -359px 25px -350px rgba(94, 10, 150, 0.2) inset;
  position: relative;
}

.schedule-payment .reminder-payments .reminder:before,
.schedule-payment .reminder-payments .payment:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  background: url(../img/pattern-purple-light.svg) repeat 0px 0px;
}

.reminder-payments .reminder img {
  z-index: 2;
  position: relative;
  margin-top: 20px;
  max-width: 547px;
}

.reminder-payments .payment img {
  z-index: 2;
  position: relative;
  margin-top: 20px;
  max-width: 356px;
}

footer {
  padding: 24px 0px;
  background: #360083;
  position: relative;
  z-index: 1;
}

footer .footer-divider {
  height: 1px;
  background: rgb(255 255 255 / 25%);
  margin: 34px 0px 24px;
  position: relative;
}

footer .footer-divider .logo {
  position: absolute;
  top: -7px;
  left: 17px;
  padding: 0px 16px;
  background: #360083;
}

footer .footer-divider .logo img {
  display: block;
}

footer .home-footer .footer-divider {
  margin: 24px 0px 48px;
}

footer .home-footer a {
  font-size: 14px;
  font-style: normal;
  font-family: "Circular Spotify Text", Arial, sans-serif;
  font-weight: 700;
  line-height: 20px;
  color: var(--white);
}

footer .extra-footer-para {
  font-size: 13px;
  text-align: justify;
  line-height: 17px;
  margin-bottom: 10px;
}

footer h4 {
  color: var(--white);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  /* margin-top: 10px; */
  opacity: 0.5;
}

footer p {
  color: var(--white);
  font-size: 16px;
  line-height: 31px;
  margin-top: 22px;
  margin-bottom: 0px;
  max-width: 300px;
}

.address-footer {
  color: var(--white);
  font-weight: 100;
  font-size: 13px;
  text-align: center;
}

footer .footer-logo img {
  width: 109px;
}

footer ul li {
  padding: 7px 0px;
}

footer .inner-items ul li {
  display: inline-block;
  padding-top: 0px;
}

footer .inner-items ul li:not(:last-child) {
  padding-right: 48px;
  position: relative;
}

footer .inner-items ul li:not(:last-child):after {
  content: "";
  height: 16px;
  width: 1px;
  background: var(--white);
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translate(0px, -50%);
}

footer .address {
  color: var(--white);
  font-size: 12px;
  line-height: 19px;
  margin-top: 10px;
}

footer h4.address-title {
  margin: 10px 0px 0px;
  font-size: 19px;
}

footer ul li a {
  color: #fefefe;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.2s ease-in-out 0.1s;
  -webkit-transition: all 0.2s ease-in-out 0.1s;
  -moz-transition: all 0.2s ease-in-out 0.1s;
  -o-transition: all 0.2s ease-in-out 0.1s;
  -ms-transition: all 0.2s ease-in-out 0.1s;
}

footer ul li button {
  color: #fefefe;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.2s ease-in-out 0.1s;
  -webkit-transition: all 0.2s ease-in-out 0.1s;
  -moz-transition: all 0.2s ease-in-out 0.1s;
  -o-transition: all 0.2s ease-in-out 0.1s;
  -ms-transition: all 0.2s ease-in-out 0.1s;
  background: none;
  border: 0;
  padding: 0;
}

footer ul li a:hover,
footer ul li button:hover {
  color: #ceb0fa;
}

footer .locations ul li {
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
  padding-left: 24px;
  padding-top: 0px;
  padding-bottom: 0px;
  position: relative;
}

footer .locations ul li:before {
  content: "";
  background: url(../img/flag-for-india.svg) no-repeat 0px 0px;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 2px;
  left: 0px;
}

footer .copyright {
  color: var(--white);
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", Arial, sans-serif;
  font-weight: 500;
  opacity: 0.5;
}

footer .address-group p {
  line-height: 1.4rem;
}

footer .contact-group P {
  line-height: 1.3rem;
}

footer .contact-group p {
  padding-bottom: 8px;
}

footer .contact-group ul li {
  line-height: 0.8rem;
  color: var(--white)
}

footer .contact-group ul li a {
  line-height: 0.8rem;
  color: var(--white)
}

/* V2 Design */
.defaultpage {}

.inner-top-section.pos-banner .inner-content {
  padding: 160px 0px 200px;
}

.pos-banner::before {
  content: "";
  position: absolute;
  top: -35px;
  right: 0;
  background: url(../img/pos-shape.svg) no-repeat top;
  width: 40%;
  height: 100%;
}

.pos-banner .container-md {
  position: relative;
}

.pos-banner .overview-img {
  position: absolute;
  bottom: 0;
  right: -100px;
}

.pos-bluebg {
  background: linear-gradient(280deg, #4600a9 0%, #7229db 100%);
  padding-bottom: 80px;
  position: relative;
  padding-top: 80px;
  margin-bottom: 60px;
}

.store-payment {
  margin-top: 0px;
  position: relative;
  z-index: 1;
}

.store-payment-img {
  margin-left: 50px;
}

.instore {
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 32px;
}

.store-payment .main-box .button {
  margin-bottom: 25px;
}

.store-payment .main-box .inner-content::after {
  content: "";
  height: 70px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  box-shadow: 0px -359px 25px -350px rgba(94, 10, 150, 0.2) inset;
  z-index: 2;
}

.store-payment .col-md-5 {
  position: relative;
  z-index: 22;
}

.store-summarynew {
  margin-top: 80px;
}

.store-summarynew h3 {
  color: #fff;
}

.store-summarynew p {
  color: rgba(255, 255, 255, 0.75);
  margin: 10px 0 0;
}

.store-summarynew .instore {
  margin-top: 56px;
}

.store-summarynew .store-payment-img {
  margin-left: 0;
}

.store-summarynew .instore {
  padding-right: 0;
}

.manage-access-img {
  margin-left: 0;
  margin-right: -40px;
}

.manage-access .inner-content h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}

.manage-access .inner-content p {
  font-size: 12px;
  color: #4600a9;
  opacity: 0.65;
  line-height: 16px;
  margin: 0 0 10px;
  padding-right: 20%;
}

.manage-access .main-box {
  background: linear-gradient(180deg, #e3dcff 0%, #aa97ff 100%);
}

.manage-access .main-box .inner-content::after {
  content: "";
  height: 70px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  box-shadow: 0px -359px 25px -350px rgba(94, 10, 150, 0.2) inset;
  z-index: 2;
}

.manage-access .col-md-5 {
  flex: 0 0 auto;
  width: 46%;
}

.manage-access .col-md-4 {
  flex: 0 0 auto;
  width: 30%;
}

.manage-access .col-md-3 {
  flex: 0 0 auto;
  width: 24%;
}

.bottom-content {
  padding-top: 6px;
}

.bottom-content h5 {
  font-size: 16px;
  margin: 10px 0 0;
  color: #4600a9;
  line-height: 20px;
}

.bottom-content p {
  font-size: 16px;
  margin: 10px 0 0;
  color: #747380;
}

.pos-devicenew {
  margin-top: 0;
  margin-bottom: 64px;
}

.pos-devicenew .main-box {
  border-radius: 24px;
  background: var(--purple-grey-light);
  position: relative;
  overflow: hidden;
  padding-top: 48px;
  border: 2px solid #e1ccff;
  position: relative;
}

.pos-devicenew .main-box::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: url(../img/purple-gradient-light-curve.svg) repeat;
  opacity: 0.2;
  border-radius: 24px;
}

.pos-devicenew .main-box .row {
  position: relative;
  z-index: 11;
}

.pos-devicenew .instore {
  padding-top: 16px;
}

.pos-devicenew .instore .button {
  margin-top: 32px;
}

.pos-devicenew .store-payment-img img {
  max-width: 320px;
}

.pos-devicenew .div-text {
  margin-left: -140px;
}

.store-summarynew.addmember .store-payment-img {
  margin-left: 0;
}

.store-summarynew.addmember .instore {
  padding-left: 0;
  padding-right: 110px;
  margin: 0;
  padding-top: 0;
}

.pos-devices-main .customize-reminder {
  padding-top: 37px;
  height: initial;
}

.pos-devices-main .customize-reminder h4 {
  opacity: 0.8;
}

.pos-devices-main .row {
  z-index: initial;
}

.pos-devices-main .pl-0 {
  z-index: 3;
  position: relative;
}

.pos-devices-main .pos-device-right .customize-reminder::before {
  opacity: 0.75;
  background: url(../img/pattern-purple-medium-2.svg) repeat 0px 0px;
}

.pos-devices-main .pos-device-left .send-reminder {
  background: linear-gradient(90deg, #b27cff 0%, #5f25b1 100%);
}

.pos-devices-main .pos-device-left .send-reminder::before {
  opacity: 0.75;
  background: url(../img/pattern-purple-medium-2.svg) repeat 0px 0px;
}

.pos-devices-main .send-reminder {
  padding: 37px 0 0;
}

.minh230 {
  min-height: 235px;
}

.mobile-login {
  display: none;
}

.menutoggle {
  display: none;
}

.getstarted.mobile {
  display: none;
}

.mobile-signin {
  display: none;
}

.menutoggle .close {
  display: none;
}

img.mobile {
  display: none !important;
}

.desktop-hide {
  display: none !important;
}

.track-receive-payment .middle-img img {
  max-width: 178px;
}

.size64 {
  font-size: 64px;
  line-height: 80px;
}

.size40 {
  font-size: 32px;
  line-height: 40px;
}

.size34 {
  font-size: 34px;
  line-height: 42px;
}

.size20 {
  font-size: 20px;
  line-height: 28px;
}

.size28 {
  font-size: 28px;
  line-height: 36px;
}

.inner-content .mw85 {
  max-width: 85%;
}

.comingsoontext {
  display: none;
}

#faq-banner {
  padding-top: 80px;
}

/* V2 Design */

/* Privacy Page Style */

.text-content-page {
  position: relative;
  padding: 80px 0px;
}

.text-content-page:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 620px;
  background: linear-gradient(180deg, #ebeeff 47.71%, #fff 100%);
}

.text-content-page .container-md {
  position: relative;
  z-index: 1;
}

.text-content-page h1 {
  color: var(--purple-dark);
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
  margin-bottom: 45px;
}

.text-content-page p {
  color: var(--dark-black);
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.text-content-page h4 {
  color: var(--dark-black);
  font-family: Arial, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 22px;
  margin-top: 55px;
}

.text-content-page ul,
.text-content-page ol {
  padding-left: 30px;
}

/* .text-content-page ul li,
.text-content-page ol li {
  color: var(--dark-black);
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
  list-style-type: disc;
}

.text-content-page ol li {
  list-style-type: decimal;
} */

.text-content-page ul li {
  color: var(--dark-black);
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
  list-style-type: disc;
}

.text-content-page .content-footer {
  color: var(--dark-black);
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-top: 70px;
}

.text-content-page h6 {
  font-weight: bold;
  font-size: 16px;
  margin: 5px 0px;
}

.text-content-page h6.gap-bottom {
  margin-bottom: 15px;
}

.text-content-page .grevience {
  margin-top: 35px;
}

/* Privacy Page Style */

/* Media Query */

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1224px;
  }
}

@media (max-width: 1380px) {
  body {
    overflow-x: hidden;
  }

  h3 {
    font-size: 28px;
    line-height: 32px;
  }

  .trusted-partners p,
  .size20 {
    font-size: 16px;
    line-height: 24px;
  }

  h1 {
    font-size: 38px;
    line-height: 48px;
  }

  .size40 {
    font-size: 34px;
    line-height: 44px;
  }

  .button {
    padding: 12px 30px;
  }

  .get-paid .gradient-card .overview-frame {
    max-width: 500px;
  }

  .nofee ul li {
    font-size: 14px;
    line-height: 22px;
  }

  .herotext p {
    font-size: 20px;
    line-height: 28px;
    padding: 0 13%;
  }

  .herotext h1 {
    font-size: 62px;
    line-height: 72px;
  }

  #home-main-section .topcard {
    max-width: 480px;
  }

  .business-cc .purple-gradient-box img.credit {
    max-width: 540px;
  }

  .vendorimg img {
    max-width: 520px;
  }

  .navbar li a,
  .navbar li a:focus {
    font-size: 15px;
  }

  .navbar .dropdown .dropdown-items {
    padding-top: 5px;
  }

  .navbar .dropdown ul a {
    font-size: 14px;
  }

  .cardcorner {
    max-width: 200px;
  }

  .monitor-spending .vendor-payments-text {
    padding: 40px 20px 0 40px;
  }

  .vendorimg img {
    max-width: 440px;
  }

  .business-cc .purple-gradient-box img.credit {
    top: -10%;
    max-width: 50%;
    right: -8%;
  }

  #home-main-section .topcard {
    left: -2%;
    max-width: 340px;
    top: -40px;
  }
}

@media (max-width: 1299px) {
  #contact-form .form-right {
    right: 0px;
    padding: 50px 25px 0px 50px;
  }
}

@media (max-width: 1240px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100%;
  }

  .menutoggle {
    display: block;
    background: transparent;
    border: 0;
    width: 32px;
    height: 32px;
    padding: 0;
  }

  header.white {
    box-shadow: none;
    background: #ebeeff;
    height: initial;
    padding: 17px 0;
    min-height: initial;
  }

  .mobilenav {
    overflow: hidden;
  }

  .mobilenav body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  .mobilenav header.white {
    background: #fff;
    width: 100%;
    height: 100%;
    display: block;
    min-height: initial;
    position: fixed;
    top: 0;
  }

  .mobilenav #navbarSupportedContent {
    display: block !important;
    width: 100%;
    padding: 25px 0;
  }

  .navbar li .nav-link,
  .navbar li .nav-link:focus {
    padding: 12px 5px !important;
    font-size: 16px;
    line-height: 24px;
  }

  .navbar .dropdown ul .nav-link {
    padding: 12px 20px 12px 48px !important;
    font-size: 16px;
  }

  .navbar .dropdown ul .nav-link {
    font-size: 16px;
  }

  .navbar .dropdown-items li .nav-link,
  .navbar .dropdown-items li .nav-link:focus {
    padding-left: 45px !important;
  }

  header.white .navbar li.loan>a {
    padding-left: 45px !important;
  }

  header.white .navbar li.loan {
    padding-left: 5px;
  }

  header.white .navbar li.loan a::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 32px;
    width: 32px;
    display: inline-block;
    border-radius: 50%;
    background-color: #ebeeff;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;
    background-image: url(../img/loan-icon.png);
  }

  .mobilenav .mobile-login,
  .mobilenav .left-menus {
    opacity: 0;
    visibility: hidden;
  }

  .mobilenav .login-item {
    display: none;
  }

  .mobilenav header #navbar {
    position: static;
  }

  .mobilenav header .container-md {
    position: static;
  }

  .mobile-login {
    display: inline-block;
    margin-right: 0;
    margin-left: auto;
    padding-right: 25px;
  }

  .mobile-login a,
  .mobile-signin {
    color: #4600a9;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  header.white .navbar-toggler svg {
    fill: #4600a9;
    width: 32px;
    height: 32px;
  }

  header.white .navbar-collapse {
    display: none !important;
  }

  .mobilenav header.white .navbar-collapse {
    display: block !important;
  }

  #navbar {
    flex-wrap: wrap;
  }

  .navbar ul {
    display: block !important;
    width: 100% !important;
  }

  .navbar ul li {
    width: 100%;
    margin: 8px 0;
  }

  .navbar .dropdown-items {
    padding: 0 5px;
  }

  .navbar .dropdown-items ul li {
    padding: 0 !important;
  }

  .navbar .dropdown ul li {
    border: 0 !important;
  }

  .navbar .dropdown ul li ul li a::after {
    height: 32px;
    width: 32px;
    left: 0;
  }

  .navbar .dropdown ul li a::after {
    height: 40px;
    width: 40px;
  }

  .navbar .dropdown ul li a::before {
    left: 10px;
  }

  .navbar .dropdown ul li a::before {
    height: 20px;
    width: 20px;
  }

  .navbar .dropdown ul li ul li a::before {
    left: 5.5px;
  }

  header.white .navbar .navbar-collapse>ul>li>a::before {
    display: none;
  }

  .navbar .dropdown ul {
    height: initial !important;
  }

  .navaction {
    display: none;
  }

  .mobilenav .navaction {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 40px 10px;
  }

  .navaction .button.small {
    padding: 11px 40px;
  }

  .mobile-signin {
    display: inline-block;
  }

  .mobilenav .menutoggle .open {
    display: none;
  }

  .mobilenav .menutoggle .close {
    display: block;
  }

  header.white .navbar li.mobile-show {
    display: block;
  }

  #home-main-section img.main-img {
    top: 0;
  }

  #home-main-section .row.main-p {
    margin-top: 20px;
  }

  .inner-content .mw85 {
    max-width: 100%;
  }

  .current-account .right-card {
    display: none;
  }

  .size64 {
    font-size: 44px;
    line-height: 54px;
  }

  .image-wrap img,
  .get-paid-collection img,
  .send-reminder img,
  .payment-image img {
    max-width: 100% !important;
  }

  .pos-devicenew .div-text {
    margin-left: -100px;
  }

  .pos-devicenew .instore {
    padding-right: 0;
  }

  .pos-devicenew .instore .button {
    margin-top: 20px;
  }

  #home-main-section .topcard {
    left: -4%;
    max-width: 20px;
    top: -20px;
  }
}

@media screen and (max-width: 1240px) and (orientation: landscape) {
  .mobilenav .navaction {
    position: static;
    padding: 60px 40px 40px;
  }

  .mobilenav header .container-md {
    position: relative;
    min-height: 80%;
    height: 100%;
    overflow-y: scroll;
  }
}

@media (min-width: 992px) {
  header {
    padding: 0px;
  }

  .navbar ul {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
  }
}

@media (max-width: 991px) {
  .slider-main .cta-link {
    position: absolute;
    left: 110px;
  }

  #home-main-section img.main-img.desktop {
    min-width: initial !important;
    width: 100%;
  }

  .navbar a,
  .navbar a:focus {
    padding: 8px 0px;
  }

  header .btn-rounded.login.mobile {
    display: inline-block;
    margin-top: 10px;
    margin-left: 0px;
    position: static;
    line-height: 34px;
    padding: 2px 25px;
  }

  header .btn-rounded.login.desktop {
    display: none;
  }

  .navbar li>a.active {
    font-weight: normal;
  }

  .content-img a img {
    margin: 0px auto 0px 40px;
  }

  .banner-content h2 {
    font-size: 32px;
    line-height: 39px;
    margin: 12px 0px;
  }

  .banner-bullets ul.d-flex {
    flex-direction: column;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -o-flex-direction: column;
    -ms-flex-direction: column;
  }

  .banner-content {
    margin-top: 30px;
  }

  .banner-content .request-demo {
    padding-top: 0px;
  }

  .watch-video {
    margin-top: 35px;
  }

  #top-banner {
    min-height: initial;
  }

  h2 {
    font-size: 32px;
    line-height: 40px;
  }

  html,
  body {
    font-size: 16px;
    line-height: 24px;
  }

  .btn-rounded.purple {
    padding: 15px 35px;
  }

  #payroll-system .content-box {
    padding-right: 0px;
  }

  #manage-corporate h3 {
    font-size: 40px;
    line-height: 55px;
  }

  #manage-corporate p {
    font-size: 19px;
    line-height: 29px;
    text-align: left;
  }

  #testemonials .top-head {
    padding: 30px 0px 0px 0px;
    text-align: center;
  }

  #testemonials .custom-nav {
    text-align: center;
  }

  #testemonials .top-head h3 {
    font-size: 45px;
    line-height: 60px;
  }

  #lets-chat h2 {
    font-size: 90px;
  }

  #lets-chat p {
    font-size: 30px;
    line-height: 45px;
  }

  .chatnow-container {
    height: 174px;
  }

  footer {
    padding: 50px 0px;
  }

  .navbar .dropdown ul {
    height: 0px;
    position: relative;
    padding-left: 50px;
    opacity: 1;
    visibility: visible;
    overflow: hidden;
    padding: 0px;
  }

  .navbar .dropdown ul li {
    border-bottom: 1px solid #eadee9;
  }

  .navbar .dropdown:hover>ul {
    height: auto;
    width: 100%;
  }

  .navbar ul>li {
    margin-left: 0px;
  }

  .content-frame .inner-content {
    top: 7px;
    left: 103px;
    height: 74.1%;
    width: 70.1%;
  }

  #faq-section .accordion {
    padding-left: 0px;
    padding-right: 0px;
  }

  #testemonials .slider-top-info {
    min-height: 350px;
  }

  #partners h2 {
    line-height: normal;
  }

  .content-frame .inner-content .content img {
    width: 100px;
    margin-top: 15px !important;
  }

  .content-frame .inner-content .content h3 {
    font-size: 30px;
    line-height: 45px;
  }

  .content-frame .inner-content .content p {
    font-size: 16px;
  }

  .content-frame .inner-content .content {
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
  }

  #testemonials .slide .slide-info {
    padding: 30px 30px 20px;
  }

  .ratings span {
    height: 28px;
    width: 30px;
    background-size: contain;
  }

  #testemonials .clientName {
    padding: 15px 20px;
  }

  .clientName .info h4 {
    font-size: 21px;
    line-height: 31px;
  }

  .clientName .info p {
    font-size: 13px;
  }

  /* #testemonials .slide-main {
    margin-top: -133px;
  } */
  .productivity-images-container span.verticalGraph {
    left: -14%;
  }

  .nav-tabs .nav-link.active:after {
    top: 12px;
    background-size: 85%;
  }

  #payroll-system .img-container img {
    max-width: 100%;
    right: -13px;
  }

  /** redesign **/
  #contact-form:before {
    display: none;
  }

  #contact-form .form-left {
    padding: 50px 0px;
    max-width: initial;
  }

  #contact-form .form-right {
    display: none;
  }

  #contact-form.thankyou-page .form-left {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: calc(100vh - 64px);
  }

  #contact-form.thankyou-page .form-left h3,
  #contact-form .form-left h4 {
    font-size: 22px;
    line-height: 28px;
  }

  #contact-form.thankyou-page .form-left .inner-title,
  #contact-form.thankyou-page .form-left .nav-tabs {
    display: none;
  }

  #contact-form .form-left p {
    color: #1f1f25;
    font-size: 14px;
    line-height: 20px;
  }

  .return-home-btn {
    margin-top: 64px;
  }

  .return-home-btn svg {
    margin-right: 4px;
    vertical-align: sub;
  }

  .cards .card.card-2,
  .cards .card.card-3 {
    padding: 15px 15px;
  }

  .cards p {
    font-size: 14px;
    line-height: 20px;
  }

  .get-paid .gradient-card .overview-frame {
    top: -8%;
    width: 48%;
  }

  .get-paid .gradient-card .columns-box {
    padding: 24px 16px 40px;
  }

  .virtual-card-banner .gradient-card .columns-box {
    padding: 24px 16px 0px;
  }

  .virtual-card-banner .gradient-card .columns-box img {
    margin-left: -18px;
    border-radius: 25px;
  }

  .get-paid .gradient-card .action {
    margin-top: 10px;
  }

  .get-paid .gradient-card .action .button {
    margin-top: 40px;
  }

  .get-paid .gradient-card h4 {
    font-size: 22px;
    line-height: 28px;
  }

  .get-paid .directions,
  .main-head .head-icon {
    height: 48px;
    width: 48px;
    line-height: 38px;
    background-size: 28px;
  }

  .get-paid .directions img,
  .main-head .head-icon img {
    max-width: 28px;
  }

  h4 {
    font-size: 22px;
    line-height: 28px;
  }

  .bulk-payment .button {
    margin-top: 20px;
  }

  .grey-pattern-full {
    padding: 32px 16px;
  }

  .payment-reminder-steps .outer-box ul li .hover-box {
    height: 120px;
    width: 120px;
  }

  .payment-reminder-steps .outer-box .inner {
    background-size: 88%;
    background-position: 62% 30%;
  }

  .payment-reminder-steps .outer-box ul li .hover-box img {
    max-width: 56px;
  }

  .cards .card.card-3 img.coupon-img {
    bottom: -45px;
    left: -16px;
  }

  .know-about-pos .card {
    padding: 15px 15px;
    min-height: 205px;
  }

  .business-cc .purple-gradient-box img.credit {
    top: -10%;
    max-width: 50%;
    right: -4%;
  }

  .purple-gradient-box {
    padding: 40px 15px;
  }

  .virtual-cards .card-group {
    padding: 90px 0px;
    background-size: 65%;
    min-height: initial;
  }

  .monitor-spending .expansion-trip {
    top: 40%;
    width: 42%;
  }

  #available-on-mobile,
  .gradient-text-card {
    padding: 60px 0px 120px;
  }

  .available-mobile {
    padding: 48px 15px;
  }

  .gradient-text-card .value {
    font-size: 76px;
    line-height: 100px;
  }

  .gradient-text-card .value span {
    font-size: 40px;
  }

  .monitor-spending {
    padding: 50px 0;
    overflow: hidden;
  }

  .know-about-pos {
    padding: 30px 0 60px;
  }

  .cards .card {
    min-height: 220px;
  }

  .card-3 .giftbox {
    right: 16px;
    bottom: 16px;
    width: 48px;
  }

  .about-top-banner p {
    font-size: 22px;
    line-height: 28px;
  }

  .about-top-banner p,
  .about-content p {
    color: #1f1f25;
    font-size: 16px;
    line-height: 24px;
  }

  .about-top-banner .inner-row p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;
  }

  .about-content .about-content-inner {
    background-image: none;
  }

  .about-content h5 {
    margin-bottom: 22px;
  }

  .about-content .about-content-inner .row {
    padding-bottom: 80px;
  }

  .about-content .about-content-inner .addresses p {
    margin-bottom: 7px;
  }

  .about-content .about-content-inner .addresses .col-lg-4:last-child {
    margin-top: 40px;
  }

  .about-top-banner .about-img {
    position: absolute;
    top: 15px;
    right: initial;
    left: 2%;
    opacity: 0.5;
  }

  .about-top-banner {
    min-height: initial;
    padding-bottom: 32px;
  }

  .about-top-banner .row {
    position: relative;
    z-index: 1;
  }

  .about-top-banner h1 {
    font-size: 36px;
    line-height: 44px;
  }

  .track-receive-payment .content-box {
    padding: 0 0px;
  }

  .collection-reminder .content-box .left-content {
    padding: 48px 24px;
  }

  .money-transfer .money-transfer-content {
    padding: 48px 0 0px;
  }

  .money-transfer .gradient-box .col-md-6 .mapImg {
    right: 0px;
  }

  .switch-account .main-box .btn-purple {
    margin-left: 0;
  }

  .current-ac-figures {
    text-align: center;
  }

  .current-ac-figures .row {
    flex-direction: column;
  }

  .current-ac-figures .col-md-5 {
    width: 100%;
  }

  .current-ac-figures .col-md-6 {
    width: 100%;
  }

  .current-ac-figures .col-md-4 {
    width: 100%;
  }

  .current-ac-figures .col-md-8 {
    width: 100%;
  }

  .current-ac-figures .col-lg-5 {
    margin: 32px 0 0;
  }

  .send-customize-reminder .customize-reminder {
    margin-top: 48px;
  }

  .pos-banner::before {
    display: none;
  }

  .pos-banner .overview-img {
    width: 50%;
  }

  .pos-device-right .customize-reminder {
    margin: 0;
  }

  .manage-access .col-md-5 {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .pos-devices-main .customize-reminder h4 {
    font-size: 16px;
    line-height: 20px;
  }

  .pos-devices-main .customize-reminder .content-left {
    max-width: 90%;
    margin-top: 40px;
  }

  .store-payment .col-md-7 {
    width: 100%;
    order: 2;
    justify-content: center !important;
  }

  .store-payment .col-md-5 {
    width: 100%;
    order: 1;
    padding-right: calc(var(--bs-gutter-x) * 0.5 + 20px);
    padding-left: calc(var(--bs-gutter-x) * 0.5 + 20px);
  }

  .store-payment-img {
    margin: 50px 0 0;
    text-align: center;
  }

  .store-payment-img img {
    display: block;
    margin: 0 auto;
  }

  #inner-top-section {
    overflow: hidden;
  }

  .blog-top-detail .title {
    font-size: 38px;
    line-height: 44px;
    max-width: 80%;
  }

  .blog-inner-content h2 {
    font-size: 32px;
    line-height: 38px;
    margin: 30px 0px 15px;
  }

  .blog-inner-content h3 {
    font-size: 28px;
    line-height: 36px;
    margin: 30px 0px 15px;
  }

  .blog-inner-content h4 {
    font-size: 24px;
    line-height: 30px;
    margin: 20px 0px 15px;
  }

  .blog-inner-content h5 {
    font-size: 22px;
    line-height: 30px;
    margin: 20px 0px 15px;
  }

  .blog-inner-content h5 {
    font-size: 18px;
    line-height: 30px;
    margin: 20px 0px 15px;
  }

  .blog-inner-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .herotext h1 {
    font-size: 52px;
    line-height: 64px;
  }

  .herotext p {
    font-size: 20px;
    line-height: 28px;
    padding: 0 5%;
  }

  h1 {
    font-size: 34px;
    line-height: 42px;
  }

  .size40 {
    font-size: 32px;
    line-height: 38px;
  }

  h3.size40 {
    font-size: 28px;
    line-height: 34px;
  }

  .get-paid .gradient-card p {
    font-size: 16px;
    line-height: 24px;
    max-width: 100%;
  }

  .payment-schedule-new .payment-text {
    padding: 20px;
  }

  .payment-schedule-new .payment-text p {
    margin: 16px 0 0;
    max-width: 100%;
  }

  .payment-schedule-new {
    padding: 80px 0;
  }

  .payment-schedule-new .row {
    margin-bottom: 80px;
  }

  .switch-account .main-box {
    padding: 48px 16px;
  }

  .switch-account h2.size40 {
    font-size: 28px;
    line-height: 36px;
  }

  .current-ac-figures {
    padding: 48px 0px 64px;
  }

  .switch-account {
    padding-bottom: 50px;
  }

  .available-mobile .col-md-6.frame img.desktop {
    display: none;
  }

  .available-mobile .col-md-6.frame img.mobile {
    display: block !important;
    max-width: 100%;
    top: 30px;
    bottom: 0;
    right: -50px;
  }

  .cardcorner {
    display: none;
  }

  .herotext p {
    font-size: 18px;
    line-height: 26px;
  }

  .hover-box:hover img.hover-img {
    transform: none;
  }

  .hover-out:hover img.hover-img {
    transform: none;
  }
}

@media (max-width: 767px) {
  .button {
    padding: 12px 32px;
    font-size: 16px;
    line-height: 24px;
  }

  img.desktop {
    display: none !important;
  }

  img.mobile {
    display: block !important;
  }

  #home-main-section img.main-img {
    top: 0;
    left: 0;
    width: 100%;
    min-width: calc(100% + 24px);
    left: -12px;
  }

  #home-main-section .row.main-p {
    margin-top: 40px;
    text-align: center;
  }

  #home-main-section .row.main-p h3 {
    font-size: 22px;
    line-height: 28px;
  }

  #home-main-section .main-p p {
    max-width: 100%;
    margin: 24px 0 0;
  }

  #home-main-section .btns-group {
    flex-direction: column;
    justify-content: center;
  }

  #home-main-section .how-works-btn {
    margin: 16px 0 0;
  }

  .how-works-btn {
    padding: 12px 32px 12px 52px;
    background-position: 20px 50%;
  }

  .cards .card.card-2,
  .cards .card.card-3 {
    padding: 36px 24px;
  }

  .cards .card.card-3 img.coupon-img {
    bottom: -36px;
    left: -25px;
  }

  .get-paid .gradient-card .inner-content {
    overflow: hidden;
  }

  .get-paid .inner-content .overflow-hidden {
    max-width: 80%;
    margin-left: -37px;
    margin-top: 15px;
  }

  .get-paid .gradient-card .overview-frame.mobile {
    min-width: calc(100% + 40px);
    position: static;
    display: block;
    margin-bottom: 20px;
    margin-left: -15px;
  }

  .virtual-card-banner .gradient-card .overview-frame.mobile {
    min-width: calc(100% + 40px);
    position: static;
    display: block;
    margin-bottom: 20px;
    margin-left: -16px;
  }

  .button.oca {
    box-shadow: 0 4px 12px rgba(255, 255, 255, 0.5);
  }

  .bulk-payment .grey-pattern-full {
    padding-top: 25px !important;
  }

  .payment-reminder-steps .bottom-content .btn-white {
    position: static;
    margin-top: 40px;
  }

  .know-about-pos .card {
    padding: 24px;
    min-height: initial;
  }

  #home-main-section {
    padding-bottom: 50px;
  }

  #cards {
    margin-top: 0px;
  }

  #know-about-pos .col-md-4:not(:last-child) {
    margin-bottom: 24px;
  }

  .poslink {
    text-align: center;
  }

  .business-cc .purple-gradient-box img.credit {
    max-width: 100%;
    position: static;
    min-width: calc(100% + 40px);
    margin-left: -20px;
  }

  .business-cc .main-head {
    text-align: left !important;
  }

  .business-cc .content-box .col-md-3.text-end {
    text-align: center !important;
  }

  .mobile-hide {
    display: none !important;
  }

  .desktop-hide {
    display: block !important;
  }

  .business-cc .purple-gradient-box .content-box {
    margin-top: 10px;
  }

  #business-cc .desktop-hide {
    min-width: calc(100% + 30px);
    margin-left: -15px;
    margin-bottom: 40px;
    margin-top: 17px;
  }

  #business-cc .desktop-hide img {
    width: 100%;
  }

  .virtual-cards .card-group {
    background: transparent !important;
    padding: 0;
  }

  .virtual-cards {
    background: url(../img/cards-group2.png) no-repeat bottom right;
    background-color: #f2efff;
    padding: 20px 0 56%;
    background-size: 100%;
  }

  .monitor-spending .content-box {
    padding-top: 120px;
    padding-bottom: 0;
  }

  .monitor-spending .content-box .vendorimg img {
    margin-left: 38px;
    margin-right: 38px;
    width: calc(100% - 40px);
    margin-top: 48px;
  }

  .monitor-spending .vendor-payments-text {
    padding: 40px 16px 0;
  }

  .monitor-spending .content-box p {
    margin-top: 12px;
  }

  .monitor-spending .content-box {
    background: url(../img/card-corner2.png) no-repeat top left;
    background-size: 100%;
  }

  .monitor-spending .col-4 {
    display: none;
  }

  .monitor-spending .grey-pattern-full {
    overflow: hidden;
  }

  .monitor-spending .col-8 {
    width: 100%;
  }

  .monitor-spending .col-8 img {
    width: 100%;
  }

  .monitor-spending .expansion-trip {
    top: auto;
    width: 90%;
    bottom: 15%;
    right: -20%;
  }

  .comingsoontext {
    text-align: center;
    font-style: italic;
    margin-top: 24px;
    color: #fff;
    opacity: 0.5;
  }

  #testemonials .owl-theme .owl-nav {
    display: none !important;
  }

  #testemonials .slide .slide-info {
    padding: 0;
  }

  .available-mobile {
    padding: 0 16px;
  }

  .available-mobile .col-md-6.frame img {
    position: static;
    width: 100%;
  }

  .available-mobile .col-md-6.frame {
    padding: 16px 0 0 10px;
    text-align: right;
    overflow: hidden;
    min-width: 103%;
  }

  .available-mobile {
    background-color: var(--purple-grey-light);
    background-image: url(../img/pattern-grey-bg.svg);
    background-repeat: repeat;
  }

  .gradient-text-card .col-md-4:not(:last-child) {
    margin-bottom: 40px;
  }

  .gradient-text-card .value {
    font-size: 72px;
    line-height: 76px;
  }

  .gradient-text-card .value span {
    font-size: 34px;
  }

  footer {
    padding: 25px 12px;
  }

  footer h4 {
    margin-bottom: 16px;
  }

  footer .col-md-3:not(:nth-child(2)) {
    margin-top: 40px;
  }

  footer ul li {
    padding: 6px 0px;
  }

  footer .col-md-8 {
    text-align: center;
  }

  footer .inner-items ul li:not(:last-child)::after {
    right: 15px;
    top: 12px;
  }

  footer .inner-items ul li:not(:last-child) {
    padding-right: 30px;
  }

  footer .footer-divider {
    margin-top: 95px;
  }

  footer .footer-divider+.row {
    position: relative;
  }

  footer .footer-divider+.row .col-md-4 {
    position: absolute;
    top: -70px;
    width: auto;
  }

  footer .copyright {
    text-align: center;
    width: 100%;
    margin-top: 10px;
  }

  .available-mobile .col-md-6 {
    position: relative;
    z-index: 11;
  }

  .cards .card {
    min-height: 200px;
  }

  .cards .card.card-2 p {
    margin-bottom: 0;
  }

  .cards .card.card-3 {
    padding-top: 24px;
  }

  .payment-reminder-steps .outer-box ul li .d-md-none {
    display: none;
  }

  .payment-reminder-steps .outer-box .inner {
    background-size: 0px;
  }

  .payment-reminder-steps .outer-box ul {
    width: 100%;
  }

  #faq-section {
    padding: 40px 0px 60px;
  }

  .faq-head {
    padding-bottom: 28px;
  }

  .track-receive-payment .content-box {
    padding: 0;
  }

  .track-receive-payment .group-img .middle-img {
    display: none;
  }

  .track-receive-payment .group-img {
    margin-top: 9px;
  }

  .track-receive-payment .group-img .col-md-6 {
    margin-top: 48px;
  }

  .track-receive-payment .group-img .col-md-6 .hover-img {
    min-width: calc(100% + 20px);
    margin-left: -10px;
  }

  .track-receive-payment {
    padding: 0 0px 70px;
  }

  .receive-payment {
    padding: 20px 0px 40px;
  }

  .collection-reminder {
    padding: 20px 0px 60px;
  }

  .receive-payment .card-payment .row .col-md-4 img {
    position: static;
  }

  .card-payment .inner-content {
    padding: 10px 16px 40px 16px;
  }

  .card-payment .inner-content .btn-box {
    margin-top: 40px;
    text-align: left !important;
  }

  .receive-payment .main-head {
    margin-bottom: 80px;
    text-align: left !important;
  }

  .card-inner-block h6 br {
    display: none;
  }

  .card-inner-block h6 {
    width: auto;
    max-width: initial;
  }

  .card-inner-block p {
    margin: 8px 0 0;
  }

  .reminder-payments .card-inner-block,
  .approve-payment .card-inner-block {
    margin-bottom: 40px;
  }

  .reminder-payments .payment {
    padding: 0 16px;
    min-height: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .reminder-payments .reminder {
    padding: 0 16px;
    min-height: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .collection-reminder .content-box .left-content {
    padding: 32px 20px;
  }

  .collection-reminder .content-box img {
    position: static;
  }

  .receive-payment .card-payment .row .col-md-4 {
    margin-top: -105px;
    margin-left: 15px;
  }

  .collection-reminder .content-box .btn-groups {
    margin-bottom: 24px;
  }

  .approve-payment {
    padding: 48px 0 60px;
  }

  .approve-payment .manage-team-access {
    min-height: 232px;
    padding: 24px;
  }

  .approve-payment .payment-approval .img-box img {
    top: 8px;
    right: 0;
  }

  .approve-payment .payment-approval {
    overflow: hidden;
  }

  .payment-schedule .transaction-card {
    padding: 20px 0 0;
    margin-top: 40px;
  }

  .money-transfer .gradient-box .col-md-6 .mapImg {
    position: static;
    margin-top: -60px;
  }

  .mobile-order-2 {
    order: 2;
  }

  .mobile-order-1 {
    order: 1;
  }

  .money-transfer .money-transfer-content {
    padding: 24px 0 0;
  }

  .money-transfer .gradient-box h5 {
    max-width: 100%;
    font-size: 22px;
    line-height: 28px;
  }

  .money-transfer .gradient-box p {
    margin: 0 0 15px;
  }

  .money-transfer .button {
    margin-top: 25px;
    box-shadow: 0 4px 15px rgba(255, 255, 255, 0.5);
  }

  .payment-schedule .paySchedule-card .inner-content h1 {
    padding: 0;
    top: 40px;
    text-align: center;
  }

  .payment-schedule .paySchedule-card {
    padding: 0;
  }

  .money-transfer {
    padding-bottom: 40px;
  }

  .switch-account .main-box {
    padding: 16px;
  }

  .switch-account .main-box .inner-content {
    padding: 32px 0 25px;
    text-align: center;
  }

  .switch-account .main-box .inner-content img.mobile {
    display: block;
    margin: 0 auto 40px;
  }

  .switch-account .main-box .inner-content h5 {
    font-size: 24px;
    line-height: 32px;
  }

  .switch-account .main-box .btn-purple {
    margin-left: 0;
    padding: 12px 44px;
  }

  .switch-account {
    margin-bottom: 0px;
  }

  .current-ac-figures .content-box {
    padding: 0;
    text-align: center;
  }

  .current-ac-figures h5 {
    margin: 0;
  }

  .current-ac-figures {
    padding: 16px 0px 64px;
  }

  .current-ac-figures p {
    margin: 12px 0 0;
    font-size: 14px;
    line-height: 22px;
  }

  .inner-top-section .inner-content {
    text-align: center;
  }

  .inner-top-section .right-card {
    top: 52%;
  }

  .inner-top-section .inner-content p {
    padding: 0 9px;
    margin: 16px 0 0;
  }

  .inner-top-section .inner-content .action-btns {
    margin: 40px 0 0;
  }

  .inner-content .action-btns .button {
    margin: 0 110px;
  }

  .inner-content .action-btns .how-works-btn {
    margin: 16px 110px 0;
    border: 1px solid #e1ccff;
  }

  .inner-top-section .overview-img {
    margin-top: 8px;
  }

  .inner-top-section .inner-content {
    padding: 54px 0px 60px;
  }

  .inner-top-section.payment-collection-banner .overview-img {
    margin-top: 40px;
  }

  .about-reminder .about-reminder-inner {
    text-align: center;
    padding: 0;
  }

  .about-reminder .about-reminder-inner h4 {
    max-width: 100%;
  }

  .get-paid.pos .row>.col-md-5 {
    padding-right: calc(var(--bs-gutter-x) * 0.5 + 16px);
    padding-left: calc(var(--bs-gutter-x) * 0.5 + 16px);
    order: 1;
  }

  .get-paid.pos .main-box::before {
    opacity: 0.2;
  }

  .get-paid.pos .main-box {
    padding-top: 24px;
  }

  .about-reminder-inner p:last-child {
    margin-bottom: 0;
  }

  .get-paid .main-box h5 {
    font-size: 22px;
    line-height: 28px;
    margin: 0 0 16px;
  }

  .send-customize-reminder .send-reminder .col-md-6.pl-0 .hover-img {
    display: none;
  }

  .send-customize-reminder .send-reminder {
    padding: 0 20px 32px;
  }

  .send-customize-reminder .send-reminder h4 {
    margin-top: 0;
    margin-bottom: 8px;
  }

  .send-customize-reminder .send-reminder::before,
  .send-customize-reminder .customize-reminder::before {
    opacity: 0.75;
  }

  .send-customize-reminder .send-reminder {
    background: linear-gradient(90deg, #b27cff 0%, #5f25b1 100%);
  }

  .send-customize-reminder .send-reminder p {
    margin: 8px 0 0;
  }

  .send-customize-reminder .send-reminder .col-md-6.pl-0 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .send-customize-reminder .customize-reminder {
    height: initial;
    padding-top: 32px;
  }

  .send-customize-reminder .customize-reminder .col-md-6.pl-0 {
    order: 1;
  }

  .send-customize-reminder .customize-reminder .col-md-6.d-flex {
    order: 2;
    margin-top: 33px;
  }

  .send-customize-reminder .customize-reminder .content-left {
    max-width: 100%;
    margin-top: 0;
    padding: 0 30px;
  }

  .send-customize-reminder .customize-reminder h4 {
    margin: 0 0 8px;
  }

  .send-customize-reminder {
    padding-bottom: 8px;
  }

  #faq-section .accordion-item .accordion-header {
    font-size: 14px;
    line-height: 20px;
    padding: 12px 12px;
  }

  #faq-section .accordion-body {
    padding: 0 12px 20px;
  }

  #faq-section p {
    margin: 5px 0 0;
    font-size: 14px;
    line-height: 20px;
  }

  #faq-section .accordion-item .accordion-header button {
    font-size: 14px;
    line-height: 20px;
  }

  .receive-payment {
    overflow: hidden;
  }

  .pos-banner .overview-img {
    position: static;
    width: 100%;
    margin: 56px 0 0;
  }

  .pos-banner .overview-img img {
    margin-right: -22px;
  }

  .inner-top-section.pos-banner .inner-content {
    padding: 54px 0px 0px;
  }

  .send-customize-reminder.pos-devices-main {
    padding-top: 8px;
  }

  .pos-devices-main .pos-device-left .send-reminder {
    padding: 41px 0 0;
  }

  .minh230 {
    min-height: initial;
  }

  .pos-device-right {
    margin-top: 48px;
  }

  .pos-device-right .content-left .button {
    display: none;
  }

  .get-paid.manage-access {
    padding-top: 48px;
  }

  .get-paid.manage-access .inner-content .col-md-4 {
    display: none;
  }

  .get-paid.manage-access .inner-content .col-md-3 {
    display: none;
  }

  .manage-access .inner-content .col-md-5 {
    width: 100%;
  }

  .manage-access .col-md-4 {
    width: 100%;
  }

  .manage-access .col-md-5 {
    width: 100%;
  }

  .manage-access .main-box {
    padding: 0;
  }

  .get-paid.manage-access {
    padding-bottom: 50px;
  }

  .pos-device-right .customize-reminder .col-md-6.pl-0 {
    order: 2;
    width: 50%;
  }

  .pos-device-right .customize-reminder .col-md-6.d-flex {
    order: 1;
    width: 50%;
    margin: 0;
  }

  .pos-device-right .customize-reminder img {
    min-width: 106%;
    margin-left: -3%;
  }

  .pos-device-right .customize-reminder .content-left {
    padding: 30px 16px 0 7px;
  }

  .manage-access-img {
    margin-left: 0;
    margin-right: 0px;
  }

  .blog-top-detail .date {
    font-size: 14px;
    line-height: 20px;
  }

  .blog-top-detail .title {
    font-size: 28px;
    line-height: 34px;
    max-width: 100%;
  }

  .blog-inner-content h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .blog-inner-content h3 {
    font-size: 22px;
    line-height: 28px;
  }

  .blog-inner-content h4 {
    font-size: 20px;
    line-height: 26px;
  }

  .blog-inner-content h5 {
    font-size: 18px;
    line-height: 24px;
  }

  .blog-inner-content h5 {
    font-size: 16px;
    line-height: 22px;
  }

  .blog-banner {
    margin: 30px 0px;
  }

  #home-main-section .btns-group .button {
    margin: 0 10px;
  }

  #home-main-section .btns-group .how-works-btn {
    margin-right: 10px;
    margin-left: 10px;
  }

  .blog-category .slide-head {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .blog-category .owl-nav button span {
    height: 15px;
  }

  .blog-category .owl-nav button {
    width: 32px;
    height: 32px;
  }

  .banner-post-slide {
    width: 100%;
    margin: 10px auto;
  }

  .banner-post-slide .blog-feature-data {
    padding: 10px 15px 20px;
  }

  .banner-post-slide .blog-feature-data .title a {
    font-size: 22px;
  }

  .banner-post-slide .blog-feature-data h3 {
    margin-bottom: 8px;
  }

  .banner-post-slide .owl-nav button,
  .blog-category .owl-nav button {
    width: 40px;
    height: 40px;
  }

  .banner-post-slide .owl-nav button span,
  .blog-category .owl-nav button span {
    width: 12px;
    height: 22px;
  }

  section.blog-category:last-of-type {
    padding-top: 60px;
    margin-bottom: 60px;
  }

  .partners {
    flex-wrap: wrap;
  }

  .partners p {
    text-align: center;
    margin: 24px 0 0;
    padding: 0;
    width: 100%;
  }

  .size20,
  .trusted-partners p {
    font-size: 16px;
    line-height: 24px;
  }

  .trusted-partners p {
    margin: 10px 0 0;
  }

  .trusted-partners img {
    max-height: 40px;
  }

  h3.size40 {
    font-size: 22px;
    line-height: 28px;
  }

  .get-paid .gradient-card p {
    margin: 16px 0 0;
    font-size: 16px;
    line-height: 24px;
  }

  .get-paid .gradient-card .action .op25 {
    display: none;
  }

  .get-paid .gradient-card .action :not(.ele-order) {
    position: absolute;
  }

  .get-paid .gradient-card .action {
    text-align: center;
    bottom: 32px;
    left: 0;
    width: 100%;
    justify-content: center;
  }

  .get-paid .gradient-card .overview-frame.mobile {
    margin-bottom: -111px;
    margin-top: 27px;
    border-bottom-right-radius: 38px;
  }

  .get-paid .gradient-card .inner-content {
    position: relative;
    padding-bottom: 70px;
  }

  .virtual-card-banner .gradient-card .inner-content {
    position: relative;
    padding-bottom: 0px;
  }

  .get-paid .gradient-card .columns-box h3 {
    margin: 0 0 12px;
  }

  .homegetpaid .text-center {
    text-align: left !important;
  }

  .head-sub {
    font-size: 20px;
    line-height: 28px;
  }

  .business-cc .purple-gradient-box {
    padding: 10px 16px 40px;
  }

  .business-cc .inner-box .col-md-6:nth-child(2) {
    order: 1;
  }

  .business-cc .inner-box .col-md-6:nth-child(1) {
    order: 2;
  }

  .business-cc .inner-box .col-md-6 {
    text-align: center;
  }

  .business-cc .inner-box .col-md-6 h3,
  .business-cc .inner-box .col-md-6 p {
    text-align: left;
  }

  .business-cc .button {
    margin: 48px 0 0;
  }

  .herotext h1 {
    font-size: 38px;
    line-height: 46px;
    letter-spacing: -1px;
    margin: 0 0 24px;
  }

  .herotext p {
    font-size: 16px;
    line-height: 24px;
    padding: 0;
  }

  #home-main-section .topcard {
    left: -40px;
    max-width: 160px;
  }

  .nofee ul li {
    font-size: 12px;
    line-height: 20px;
  }

  .nofee ul li::after {
    content: "|";
    display: inline-block;
    margin: 0 8px;
  }

  .nofee ul li:nth-child(3n):after {
    display: none;
  }

  .nofee .d-sm-none {
    display: none;
  }

  .nofee {
    padding: 32px 0 0;
  }

  .nofee ul {
    column-gap: 0;
    row-gap: 5px;
  }

  .virtual-cards .button {
    box-shadow: 0 8px 15px rgba(54, 0, 131, 0.2);
    margin-top: 32px;
  }

  .inner-top-section .right-card {
    display: none;
  }

  .size34 {
    font-size: 32px;
    line-height: 40px;
  }

  .size28 {
    font-size: 26px;
    line-height: 34px;
  }

  .current-ac-figures h4 {
    margin: 0 0 12px;
  }

  .payment-schedule-new {
    padding: 56px 0;
  }

  .payment-schedule-new .row {
    margin-bottom: 56px;
    text-align: center;
  }

  .payment-schedule-new .payment-text {
    padding: 24px 0 0;
  }

  .payment-schedule-new .payment-text p {
    font-size: 14px;
    line-height: 20px;
    margin: 8px 0 0;
  }

  .inner-top-section .inner-content .d-sm-none {
    display: none;
  }

  .inner-top-section .inner-content .d-sm-block {
    display: block;
  }

  .payment-collection-banner img {
    max-width: 100%;
  }

  .current-account.inner-top-section .overview-img {
    margin-top: 20px;
  }

  .inner-top-section .inner-content .action-btns {
    padding-top: 8px;
  }

  .inner-top-section.payment-collection-banner .overview-img {
    margin-top: 20px;
  }

  .about-reminder {
    padding: 80px 0px 80px;
  }

  .about-reminder-inner p {
    margin-top: 16px;
  }

  .get-paid-collection img {
    max-width: 100%;
  }

  .get-paid.screminder .main-box {
    background: linear-gradient(180deg, #d0c6ff 0%, #8d7bdd 100%);
  }

  .get-paid.screminder .inner-content {
    text-align: center;
  }

  .get-paid.screminder .inner-content p {
    font-size: 14px;
    line-height: 20px;
  }

  .get-paid.screminder .inner-content h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .send-customize-reminder.new .send-reminder {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }

  .send-customize-reminder.new .send-reminder .business-invoice {
    padding-top: 0;
    padding-right: 0;
  }

  .store-payment .instore {
    padding: 8px 32px 32px;
    text-align: center;
  }

  .store-payment-img {
    padding: 0;
    margin: 0;
  }

  .store-payment-img img {
    width: 100%;
    display: block;
  }

  .store-summarynew {
    text-align: center;
  }

  .store-summarynew .instore {
    padding: 20px 8px 0;
    margin: 0;
  }

  .store-summarynew.addmember .instore {
    padding: 20px 8px 0;
    margin: 0;
    text-align: center;
  }

  .pos-bluebg {
    padding-bottom: 64px;
    margin-bottom: 30px;
  }

  .pos-devicenew .instore .button {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .pos-devicenew .store-payment-img img {
    width: auto !important;
    margin: 0 auto;
    display: block;
  }

  .pos-devicenew .main-box {
    padding-top: 48px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .pos-bluebg {
    padding-top: 64px;
  }

  .pos-devicenew .instore {
    padding: 0;
  }

  .pos-devicenew .store-payment-img {
    padding: 0;
  }

  .pos-devicenew .mobile-order-1 {
    width: 100%;
    text-align: center;
    margin: 0;
    text-align: center;
  }

  .pos-devicenew h3.size34 {
    font-size: 24px;
    line-height: 32px;
  }

  .pos-devicenew .mobile-order-2 {
    width: 100%;
    padding: 32px 32px 0;
    text-align: center;
  }

  .pos-devicenew .mobile-order-1 .store-payment-img img {
    min-width: 160%;
  }

  .pos-devicenew h3.size40 {
    font-size: 16px;
    line-height: 20px;
  }

  .pos-devicenew {
    margin-top: 0;
    margin-bottom: 64px;
  }

  .pos-devicenew.formobile {
    text-align: center;
  }

  .pos-devicenew.formobile .size40 {
    font-size: 24px;
    line-height: 32px;
    color: #fff;
  }

  .pos-devicenew.formobile p {
    color: rgba(255, 255, 255, 0.75);
    margin: 16px 0 0;
  }

  .available-mobile h3 {
    font-size: 20px;
    line-height: 28px;
  }

  #available-on-mobile,
  .gradient-text-card {
    padding: 60px 0px 80px;
  }

  .available-versions {
    padding-bottom: 80px;
  }

  .money-transfer .money-transfer-content h3 {
    padding-right: 0;
    text-align: center;
  }

  .size64 {
    font-size: 28px;
    line-height: 34px;
  }

  .switch-account .main-box .inner-content p {
    margin: 0 0 32px;
  }

  .current-ac-figures h4 {
    font-size: 24px;
    line-height: 32px;
  }

  .store-summarynew {
    margin-top: 64px;
  }

  .scremindernew .get-paid-collection img {
    margin-left: 0px;
  }

  .scremindernew {
    padding: 32px 0 0;
  }

  .scremindernew .mobile-order-1 {
    text-align: center;
    padding: 0 32px 32px;
  }

  .payment-schedule-new.collection-page {
    padding-bottom: 214px;
  }

  .text-content-page h1 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 30px;
  }

  .text-content-page h4 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 15px;
    margin-top: 35px;
  }

  .text-content-page .content-footer {
    margin-top: 50px;
  }

  .nav-tabs {
    border: 0px;
    column-gap: 10px;
    row-gap: 10px;
  }

  .nav-tabs .nav-link {
    padding: 8px 12px;
    text-align: left;
    font-size: 14px;
    line-height: 24px;
    min-width: 80px;
    text-align: center;
  }

  #faq-section .accordion {
    padding-top: 30px;
  }
}

#get-paid::before {
  content: "";
  display: block;
  height: 500px;
  margin: -500px 0 0 0;
}

@media (max-width: 479px) {
  .return-home-btn {
    display: block;
    text-align: center;
  }

  .payment-reminder-steps .outer-box ul li .d-md-none {
    display: block;
    font-size: 14px;
    line-height: 20px;
    margin: 8px 0 0;
  }

  .payment-reminder-steps .outer-box ul {
    row-gap: 24px;
  }

  .payment-reminder-steps .outer-box ul li:nth-child(2n) {
    position: relative;
    transform: translateY(30px);
  }

  .payment-reminder-steps .outer-box ul {
    position: relative;
    width: calc(100% + 32px);
    margin-left: -16px;
    flex-wrap: wrap;
  }

  .payment-reminder-steps .outer-box ul li {
    width: 50%;
    text-align: center;
    position: relative;
    z-index: 1;
  }

  .payment-reminder-steps .outer-box ul li .hover-box {
    margin: 0 auto;
    width: 96px;
    height: 96px;
  }

  .payment-reminder-steps .outer-box {
    overflow: hidden;
  }

  .payment-reminder-steps .outer-box .inner {
    padding-top: 16px;
  }

  .payment-reminder-steps .bottom-content {
    margin-top: 70px;
    position: relative;
    z-index: 1;
  }

  .payment-reminder-steps .bottom-content h4 {
    margin: 0;
  }

  .payment-reminder-steps .bottom-content p {
    margin: 16px 0 0;
  }

  .payment-reminder-steps .bottom-content .btn-white {
    box-shadow: 0 8px 16px rgba(54, 0, 131, 0.2);
  }

  .payment-reminder-steps .outer-box ul {
    position: relative;
  }

  .payment-reminder-steps .outer-box ul::before {
    content: "";
    width: 100%;
    height: 160%;
    /* background: url(../img/steps-line-mobile.png) no-repeat top left; */
    position: absolute;
    top: 40px;
    right: -25%;
    background-size: 110%;
  }

  .payment-reminder-steps .col-md-3 {
    position: relative;
  }

  .payment-reminder-steps .col-md-3::before {
    content: "";
    width: 100%;
    height: 100%;
    /* background: url(../img/steps-line-mobile.png) no-repeat left bottom; */
    position: absolute;
    bottom: 20px;
    right: -15%;
    background-size: 150%;
  }

  .payment-reminder-steps .col-md-3 .button {
    position: relative;
    z-index: 1;
    left: auto;
    right: auto;
  }

  .inner-content .action-btns .button {
    margin: 0 20px;
  }

  .inner-content .action-btns .how-works-btn {
    margin: 16px 20px 0;
  }

  .collection-reminder .content-box .btn-purple {
    margin-right: -16px;
  }

  .trusted-partners img {
    max-width: 100%;
  }
}

@media (max-width: 380px) {
  .payment-reminder-steps .outer-box ul::before {
    height: 150%;
    right: -22%;
    background-size: 115%;
  }

  .payment-reminder-steps .col-md-3::before {
    width: 104%;
    bottom: 10px;
    right: -15%;
    background-size: 115%;
  }
}

input[type="checkbox"]:checked {
  background-color: var(--purple-dark);
  border-color: var(--purple-dark);
  box-shadow: 0 0 0 0.25rem var(--purple-shadow);
  outline-color: var(--purple-dark);
}

input[type="checkbox"]:focus {
  border-color: var(--purple-dark);
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--purple-shadow);
}

input[type="checkbox"]:focus-visible {
  outline-color: var(--purple-dark);
}

/* Corporate Credit Card */
.first-half-background {
  height: 350px;
  background-color: #ffffff;
  overflow: visible;
}

@media screen and (max-width: 992px) {
  .first-half-background {
    height: 30px;
  }
}

.vendor-payout-banner .content-box {
  padding-top: 0px !important;
  background: none;
  background-size: 100%;
}

.vendor-payout-banner .ccc-vendor-img img {
  padding-top: 40px;
}

.monitor-spending {
  background: none;
}

.last-half-background {
  height: 1829px;
  background-image: url(../img/ccc-shape-background.svg);
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f2efff;
}

.section1 {
  width: 100%;
  background-color: #ffffff;
  /* position: relative; */
}

.background-shape1 {
  /* position: relative; */
  width: 100%;
}

.optimize-info-box {
  /* position: absolute;
  top: 230px; */
  margin: auto;
  max-width: 1220px;
}

.payment-heading {
  /* position: absolute;
  top: 730px; */
  padding-top: 122px;
  margin-inline: auto;
  text-align: center;
  max-width: 1220px;
}

@media screen and (max-width: 992px) {
  .payment-heading {
    padding-top: 70px;
  }
}

.optimize-box {
  background-color: #f2efff;
  display: inline-flex;
  padding: 48px 44px 6px 48px;
  align-items: center;
  border-radius: 24px;
}

.raised-block {
  background: rgba(255, 255, 255, 0.5);
  min-height: 320px;
  align-items: center;
  border-radius: 24px;
  margin-bottom: 10px;
  padding: 48px 44px 56px 48px;
}

@media screen and (max-width: 992px) {
  .raised-block {
    background: rgba(255, 255, 255, 0.5);
    min-height: 320px;
    align-items: center;
    border-radius: 24px;
    margin-bottom: 10px;
    padding: 28px 24px 26px 28px;
  }
}

.optimize-text {
  color: var(--Black-900, #1f1f25);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
}

.txn-fees-box {
  /* position: absolute;
  top: 1000px; */
  margin-top: 80px;
  margin-inline: auto;
  max-width: 1220px;
}

.txn-fees-box img.mobile {
  width: 900px;
}

.max-treasury-earning-box .content-box {
  padding-top: 0px !important;
  background: none;
  background-size: 100%;
}

.max-treasury-earning-box {
  /* position: absolute;
  top: 1350px; */
  margin-top: 64px;
  margin-inline: auto;
  max-width: 1220px;
}

.max-treasury-earning-box .vendorimg img {
  padding-top: 30px;
  padding-left: 40px;
  max-width: 540px;
}

.max-treasury-earning-box .max-treasury-earning-text {
  padding-top: 80px;
  padding-left: 0px;
}

@media screen and (max-width: 992px) {
  .max-treasury-earning-box {
    padding-top: 0px;
  }

  .max-treasury-earning-box .max-treasury-earning-text {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 50px;
  }
}

.invoice-discouting-box {
  /* position: absolute;
  top: 1732px; */
  margin-top: 150px;
  margin-inline: auto;
  overflow: visible;
  max-width: 1220px;
}

.invoice-discouting-box .payment-image {
  padding: 25px 18px;
  /* width: 590px; */
  border-radius: 24px;
  border: 1px solid var(--Foundation-Purple-purple-200, #b78af7);
  background: rgba(255, 255, 255, 0.5);
}

/* @media screen and (max-width: 992px) {
  .invoice-discouting-box .payment-image{
    padding: 25px 18px;
    border-radius: 24px;
    border: 1px solid var(--Foundation-Purple-purple-200, #B78AF7);
    background: rgba(255, 255, 255, 0.50);
  }
} */

/* .section2{
  top: 200px;
  overflow: hidden;
  width: 100%;
  background-color: #FFFFFF;
  position: relative;
} */

.section2 {
  width: 100%;
  padding-top: 0px;
  /* background-color: #FFFFFF;
  background-image: url(../img/ccc-shape-2.svg);
  position: relative;
  background-position:  bottom 200px left 0px;
  background-repeat: no-repeat;
  /* background-size: cover;
  background-size: 100%;
  position: relative; */
}

.virtual-card-section {
  width: 100%;
  background-image: url(../img/ccc-shape-2.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  position: relative;
}

@media screen and (max-width: 992px) {
  .virtual-card-section {
    background-image: url(../img/ccc-shape-2.svg);
    position: relative;
    background-position: bottom 80px right -100px;
    background-repeat: no-repeat;
    background-size: 355%;
  }
}

.virtual-card-banner {
  /* position: absolute;
  top: 0px;
  left: 0px;
  right: 0px; */
  margin-top: 130px;
  margin-inline: auto;
  overflow: visible;
  max-width: 1220px;
}

@media screen and (max-width: 992px) {
  .virtual-card-banner {
    margin-top: 70px;
  }
}

.virtual-card-banner .gradient-card .overview-frame {
  z-index: 1;
  position: absolute;
  right: 80px;
  top: 73px;
  overflow: hidden;
  max-width: 650px;
}

.virtual-card-employee-box {
  /* position: absolute;
  top: 450px;
  left: 0px;
  right: 0px; */
  margin-top: 180px;
  margin-inline: auto;
  overflow: visible;
  max-width: 1220px;
}

.receipt-banner-box {
  /* position: absolute;
  top: 830px;
  left: 0px;
  right: 0px; */
  margin: auto;
  padding-bottom: 300px;
  overflow: hidden;
  max-width: 1220px;
}

.receipt-banner-box .grey-pattern-full {
  padding-left: 66px;
  padding-top: 36px;
  padding-right: 30px;
  padding-bottom: 0px !important;
  border-radius: 24px;
  border: 1px solid var(--Foundation-Purple-purple-200, #b78af7);
}

@media screen and (max-width: 992px) {
  .receipt-banner-box .grey-pattern-full {
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 0px !important;
    border-radius: 24px;
    border: 1px solid var(--Foundation-Purple-purple-200, #b78af7);
  }

  .receipt-banner-box .vendor-payments-text {
    padding: 10px;
  }

  .receipt-banner-box {
    margin-top: -50px;
  }
}

/* .vendor-payout-banner .content-box {
  padding-top: 0px !important;
  background: none;
  background-size: 100%;
} */

.receipt-banner-box img {
  width: 900px !important;
}

.max-treasury-earnings img {
  margin-top: 10px;
  display: block;
}

.our-client-logo-section3 {
  /* position: relative; */
  /* top: 150px; */
  /* height: 400px; */
  width: 100%;
  background-color: var(--Foundation-Purple-purple-50);
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 100px;
}

.integration-box {
  margin: auto;
  overflow: visible;
  max-width: 1220px;
}

.ccc-faq {
  margin: auto;
  margin-top: 100px;
  max-width: 1220px;
  /* height: 1500px; */
}

.accordion-ccc {
  background: #f2efff !important;
}

.available-mobile-ccc {
  max-width: 1220px;
  margin: auto;
  min-height: 480px;
  overflow: hidden;
}

.available-mobile-ccc .col-md-6 {
  position: relative;
}

.available-mobile-ccc .col-md-6.frame img {
  position: absolute;
  top: 26px;
  /* max-width: 506px; */
}

.available-mobile-ccc h3 {
  line-height: 48px;
  margin-bottom: 16px;
}

@media screen and (max-width: 992px) {
  .available-mobile-ccc .col-md-6.frame img {
    /* background-color: #18c; */
    text-align: center;
    align-items: center;
  }

  .available-mobile-ccc h3 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.available-mobile-ccc .col-md-6 p {
  color: #1f1f25;
  opacity: 0.6;
  padding-right: 10%;
}

.available-mobile-ccc .device-list {
  margin-top: 32px;
}

.available-mobile-ccc .device-list ul {
  margin: 0px;
  padding: 0px;
}

.available-mobile-ccc .device-list ul li {
  display: inline-block;
  margin-right: 32px;
}

.vendorPayout {
  border-bottom-right-radius: 24px;
  max-width: 560px;
  display: block;
  /* bottom: 0; */
  /* right: 0; */
  padding: 0;
}

.payment-collection-banner {
  position: relative;
}

.vendor-payout-section {
  background-image: url(../img/ccc_bg_item1.svg);
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: var(--Foundation-Purple-purple-50);
}

@media screen and (max-width: 992px) {
  .vendor-payout-section {
    background-image: url(../img/ccc_bg_item1.svg);
    position: relative;
    background-position: bottom 980px right -100px;
    background-repeat: no-repeat;
    background-size: 130%;
    background-color: var(--Foundation-Purple-purple-50);
  }
}

@media screen and (min-width: 992px) {
  .vendor-payout-banner {
    position: absolute;
    top: -220px;
  }

  .spacer-250 {
    position: relative;
    height: 250px;
  }
}

.statutory-payments-banner .gradient-card .inner-content img {
  position: absolute;
  top: 60px;
  right: 60px;
  height: 250px;
}

@media screen and (max-width: 992px) {
  .statutory-payments-banner {
    height: 500px;
  }

  .statutory-payments-banner .inner-content {
    height: 540px;
  }

  .statutory-payments-banner .gradient-card .inner-content img {
    padding-right: 30px;
    padding-left: 20px;
    height: 220px;
  }
}


/* BLOG STYLE CSS HERE */
.blog-title {
  color: var(--Black-900, #1F1F25) !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 36px !important;
}

.shortened-description {
  color: var(--Black-900, #1F1F25) !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important;
}